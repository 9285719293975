import styled from 'styled-components';


export const CardRoot = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 520px;
  height: 380px;
  padding: 2px;
  border-radius: 8px;
  margin: 12px;
  box-shadow: 2px 2px 8px 0px var(--shadow-color);
  background-color: #fcfcfc;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 512px;
  height: 60px;
  padding: 2px 4px;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 8px 8px 0px 0px;
  background-color: #e7e7e7;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.verticalAlign};
  align-items: center;
  width: 504px;
  height: 100%;
  padding: 4px 8px;
  border-radius: 8px;
  /* margin: 2px 0px 2px 0px; */
  background-color: #f9f9f9;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #f9f9f9;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 512px;
  height: 40px;
  padding: 2px 4px;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid #DFDFDF;
  background-color: #f2f2f2;
`;

export const CardIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0px;
  margin: 0px 0px 0px 8px;
  font-size: 30px;
  color: var(--primary-color);
  background-color: inherit;
`;

export const CardHeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 8px;
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
`;

export const CardFooterText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
  background-color: inherit;
`;