import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex: 1;
justify-content: center;
align-items: center;
flex-direction: column;
height: 100vh;
overflow: hidden;
background-color: #333;
color: #fff;
`;

export const Logo = styled.img`
width: auto;
height: 170px;

`;

export const InputArea = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
margin-top: 15px;
`;

export const Input = styled.input`
margin-top: 15px;
width: 330px;
height: 18px;
border-radius: 15px;
border: none;
padding: 17px 20px;
font-size: 16px;
background-color: #EEE;
color: #000;
outline: none;
`;

export const Button = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 370px;
height: 60px;
border-radius: 15px;
margin-top: 15px;
background-color: #1E90FF;
font-weight: 600;
color: #fff;
cursor: pointer;
transition: transform 0.25s ease-in-out;
  &:hover {
    background-color: ${props => props.hoverColor};
    transition: 0.25s ease-in-out;
    font-weight: 600;
  }
  &:active {
    transform: scale(0.95);
  }
`;

export const ButtonClean = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 330px;
height: 40px;
margin-top: 15px;
background-color: rgba(0, 0, 0, 0.01);
border: none;
font-size: 14px;
color: #fff;
cursor: pointer;
transition: transform 0.25s ease-in-out;
&:hover {
  font-weight: bold;
  font-size: 16px;
}
&:active {
    transform: scale(0.95);
  }
`;

