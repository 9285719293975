import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import AvatarEditor from 'react-avatar-editor'


import Api from '../../services/api'

import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'


import { UserContext } from '../../contexts/UserContext';
import { ModalAlert } from '../../components/ModalAlert'


// MATERIAL UI – MATERIAL ICONS ------------------------------------------------
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import ConstructionIcon from '@mui/icons-material/Construction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import LockIcon from '@mui/icons-material/Lock';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import StoreIcon from '@mui/icons-material/Store';
import TaskIcon from '@mui/icons-material/Task';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BallotIcon from '@mui/icons-material/Ballot';


// -----------------------------------------------------------------------------


import Cursor from 'quill/blots/cursor';





export const MainHeader = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, reset} = useForm({ mode: 'resolver' });

  const editor = useRef(null);
  const API = process.env.REACT_APP_BACKEND;

  const { state: user } = useContext(UserContext)
  const { dispatch } = useContext(UserContext);

  const [step, setStep] = useState(1);
  const [modal, setModal] = useState(false)
  const [subMenu2, setSubMenu2] = useState(false)
  const [subMenu3, setSubMenu3] = useState(false)
  const [subMenu4, setSubMenu4] = useState(false)

  const [type, setType] = useState('')
  const [company, setCompany] = useState([])
  const [lawyers, setLawyers] = useState([])
  const [users, setUsers] = useState([])
  const [phaseType, setPhaseType] = useState([])
  const [selectedPhase, setSelectedPhase] = useState([])
  const [atvs, setAtvs] = useState([])


  const [modalAlert, setModalAlert] = useState('')
  const [modalAlertColor, setModalAlertColor] = useState('')
  const [popUp, setPopUp] = useState(false)
  const [showCanvas, setShowCanvas] = useState(false)
  const [showChangePhoto, setShowChangePhoto] = useState(false)
  const [file, setFile] = useState(false)
  const [canvasFile, setCanvasFile] = useState(false)
  const [convertedFile, setConvertedFile] = useState(false)


  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [tokens, setTokens] = useState([])
  const messageEl = useRef('Chat')

  const [isActive, setIsActive] = useState(true)






  const GetSelect =  async (value) => {
    if (value === 1) {
      let res = await Api.getCompanies()
      setCompany(res)
    } else if (value === 2) {
      let res = await Api.getLawyers()
      setLawyers(res)
    } else if (value === 3)  {
      let res = await Api.getUsers()
      setUsers(res)
    } else {
      let res = await Api.getTiposFase()
      setPhaseType(res)
    }
  }

  const onSubmit = async (data) => {
    let res = await Api.addProcess(data)
    let process = res.toString()
    
    let res2 = await Api.addPhase(
      {
        "codfase": selectedPhase,
        "idprocesso": process
      })
    let phase = res2.toString()
    
    let atvsData = atvs.map(i => ({...i, fk_tbfases: phase})) 
    let res3 = await Api.addAtvs(atvsData)

    console.log(res)
    setModal(false)
    setStep(1)
    setAtvs('')
    window.location.reload(false)
  };


  const MenuItemClick = (destination) => {
    navigate(destination);
  }

  const StepClick = (stepPage, type) => {
    setStep(stepPage)
    setType(type) 
  }

  const ModalClick = () => {
    setModal(true);
  }

  const CloseModalClick = () => {
    setModal(false)
    setStep(1)
    setAtvs('')
  }


  const GetAtvs =  async (value) => {
    setSelectedPhase(value)
    let res = await Api.getAtvs(value)
    setAtvs(res)
  }


  const LogoffClick = () => {
    localStorage.removeItem('SessionID')
    navigate('/Login')
  }

  
  const upLoad = async () => {
    // let res = await Api.upLoad(canvasFile)
    let res2 = await Api.postUser({id: user.id, photo: canvasFile})
    setPopUp(false)
    console.log(res2)
  }


  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      // console.log("APERTOU ESC!")
      setSubMenu2(false);
      setSubMenu3(false);
      setSubMenu4(false);
      setPopUp(false);
      setModal(false);
      setStep(1);
      setAtvs('');
    }
  }

  // document.onkeydown = function (e) {
  //   if (e.key === 'Enter' || e.which === 13 || e.keyCode === 13) {
  //     alert("APERTOU ENTER!")
  //   }
  // }



  useEffect(() => {
    reset();
  }, [modal]);

  // useEffect(() => {
  //     Convert()
  // }, [canvasFile]);





  return (
    <G.Container style={{ width: '100%', height: '100%', flexDirection: 'row', justifyContent: 'flex-start' }}>
      
      <div style={{ position: 'absolute' }}>
        {/* <G.HeaderLogo src={"/logo.png"} style={{ borderRadius: '50%', boxShadow: '0px 0px 1px 0px var(--shadow-color)' }}/> */}
        <G.HeaderLogo src={"/logo.png"} style={{ borderRadius: '50%' }}/>
      </div>

      <div style={{ width: '100%', margin: '0px' }}>
        <G.OptionsArea style={{ position: 'absolute', right: '0', top: '0', margin: '16px 12px 12px 12px' }}>

          {popUp &&
            <G.PopUpAreaFlex 
              style={{ 
                width: '280px', 
                padding: '0px', 
                position: 'absolute', 
                justifyContent: 'center', 
                alignItems: 'center', 
                marginTop: showCanvas ? '675px' : '350px', 
                backgroundColor: '#333',
                boxShadow: '0px 01px 2px 2px #ccc', 
                border: '1px solid #bbb', 
                marginLeft: '-150px'
              }}
            >
                                      
                <G.Image 
                  style={{ borderRadius: '50%', border: '3px solid #777', width: '85px', height: '85px', marginTop: '-40px', backgroundColor: '#333' }} 
                  src={canvasFile ? canvasFile : user.photo ? user.photo : '/person.png'}
                />
                <label style={{marginTop: '-10px', color: '#fff'}} htmlFor="formId">
                  <AddAPhotoIcon style={{fontSize: '30px', cursor: 'pointer', color: 'rgba(0,0,0,0  0.5)'}}/>
                </label>
                <G.Input id='formId' type="file" style={{ display: 'none' }} onChange={e => setShowCanvas(true) + setFile(e.target.files[0])} />
                <G.DistacInfoText style={{marginTop: '10px'}}>
                  {user.first_name +' '+ user.last_name}
                </G.DistacInfoText>
                <G.Text>
                  {user.email}
                </G.Text>
                {showCanvas &&
                  <div style={{backgroundColor: '#000', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '10px 0px', marginTop: '15px'}}>
                    <AvatarEditor
                      ref={editor}
                      image={file ? URL.createObjectURL(file) : null}
                      width={150}
                      height={150}
                      borderRadius={75}
                      border={50}
                      color={[0, 0, 0, 0.6]} // RGBA
                      scale={1.2}
                      rotate={0}
                    />
                    <div style={{display: 'flex', justifyContent: 'space-around', width: '50%'}}>
                      <CheckIcon 
                        onClick={() => {if (editor) {
                            setCanvasFile(editor.current.getImageScaledToCanvas().toDataURL())
                            setShowCanvas(false)
                          }
                         }
                        } 
                        style={{padding: '7px', border: '2px solid #fff', borderRadius: '50%', cursor: 'pointer'}} 
                      />
                      <CloseIcon 
                        onClick={() => setShowCanvas(false)} 
                        style={{padding: '7px', border: '2px solid #fff', borderRadius: '50%', cursor: 'pointer' }} 
                      />
                    </div>  
                  </div>
                }
              <G.OptionsArea style={{ flexDirection: 'row', marginBottom: '15px' }}>
                <G.Button 
                  onClick={() => setPopUp(false) + setCanvasFile(false) }
                  style={{ backgroundColor: '#E95839', marginTop: '12px', boxShadow: 'none'}} 
                >
                  Fechar
                </G.Button>
                {file &&        
                  <G.Button onClick={() => upLoad()} style={{ backgroundColor: '#2E8B57', marginTop: '12px', boxShadow: 'none'}}>
                    Salvar
                  </G.Button>
                }
              </G.OptionsArea>
            </G.PopUpAreaFlex>
          }
          <C.UserArea onClick={() => setPopUp(popUp === true ? false : true)}>
            {user.foto !== null
              ? <G.Image 
                  src={canvasFile ? canvasFile : user.photo ? user.photo : '/person.png'} 
                  style={{ width: '32px', borderRadius: '50%', border: '3px solid #777', marginRight: '3px' }}
                />
              : <G.Image 
                  src={'/person.png'} 
                  style={{ minWidth: '32px', borderRadius: '22px', border: '2px solid #fff', marginRight: '3px' }} 
                />
            }
            <C.UserInfoText >{user.first_name}</C.UserInfoText>
          </C.UserArea>
          <G.ButtonMenuExit>
            <ExitToAppIcon fontSize='large' onClick={LogoffClick}/>
          </G.ButtonMenuExit>
        </G.OptionsArea>

        <G.TrayMenu>
          <G.Menu style={{ width: '800px', justifyContent: 'start', backgroundColor: 'inherit', color: '#fff' }}></G.Menu>
        </G.TrayMenu>

        <G.AreaMenu style={{ fontSize: '15px'}}>
          
          {/* /// MENU ADIC. PROCESSO /// */}
          {/* {user.adm === 1 &&
            <G.MenuSpecial>
              <G.ButtonMenuSpecial onClick={() => ModalClick() + setSubMenu2(false) + setSubMenu3(false) + setSubMenu4(false)}>
                <AddCircleOutlineIcon style={{ marginRight: '8px', color: '#fff' }}/>
                <G.SmallTitle style={{ color: '#fff' }}>Chamados</G.SmallTitle>
              </G.ButtonMenuSpecial>
            </G.MenuSpecial>
          } */}

          <G.Menu style={{ width: '760px' }}>

            {/* ### MENU – DASHBOARD ### */}
            <G.ButtonMenu onClick={() => MenuItemClick('/Dashboard') + setSubMenu2(false) + setSubMenu3(false) + setSubMenu4(false)}>
              <DashboardIcon style={{ marginRight: '8px' }}/>
              <G.SmallTitle>Dashboard</G.SmallTitle>
            </G.ButtonMenu>
            
            {/* ### MENU #1 – CHAMADOS ### */}
            <G.ButtonMenu onClick={() => MenuItemClick('/Tickets') + setSubMenu2(false) + setSubMenu3(false) + setSubMenu4(false)}>
              <TaskIcon style={{ marginRight: '8px' }}/>
              <G.SmallTitle>Chamados</G.SmallTitle>
            </G.ButtonMenu>

            {/* ### MENU #2 – CADASTROS ### */}
            <G.ButtonMenu 
              onClick={() => setSubMenu2(subMenu2 === false ? true : false) + setSubMenu3(false) + setSubMenu4(false)} 
              style={{ backgroundColor: user.adm !== 1 ? '' : subMenu2 ? '#EDEDFF' : '' }}
            >
              
              {/* <AppRegistrationIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
              <G.SmallTitle >Cadastros</G.SmallTitle>
              {user.adm !== 1 ? <LockIcon fontSize="small" /> : subMenu2 ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}

              {!isActive && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', color: '#888' }}>
                  <AppRegistrationIcon style={{ marginRight: '8px' }}/>
                  <G.SmallTitle style={{ marginRight: '2px' }}>Cadastros</G.SmallTitle>
                  <LockIcon fontSize="small" />
                </div>
              }
              {isActive && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                  <AppRegistrationIcon style={{ marginRight: '8px' }}/>
                  <G.SmallTitle style={{ marginRight: '2px' }}>Cadastros</G.SmallTitle>
                  {subMenu2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              }

              {/* ### SUBMENU – MENU #2 – CADASTROS ### */}
              {(isActive && subMenu2) && 
                <G.PopUpAreaMenu style={{ alignItems: 'center', padding: '12px 16px', position: 'absolute', top: '-8px' }}>
                  <G.ButtonMenu style={{ marginBottom: '6px' }} onClick={() => MenuItemClick('/MyCompany')}>
                    <G.TextButtonSubMenu>
                      <StoreIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Minha Empresa</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/ServiceTypes')}>
                    <MiscellaneousServicesOutlinedIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Tipos de Serviço</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Services')}>
                    <MiscellaneousServicesOutlinedIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Serviços</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Customers')}>
                    <BusinessIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Clientes</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Suppliers')}>
                    <ConstructionIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Fornecedores</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Employees')}>
                    <BadgeIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Funcionários</G.SmallTitle>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Others')}>
                    <BallotIcon style={{ marginRight: '8px' }}/>
                    <G.SmallTitle>Outros</G.SmallTitle>
                  </G.ButtonMenu>
                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} /> */}
                </G.PopUpAreaMenu>
              }
            </G.ButtonMenu>

            {/* ### MENU #3 – FINANCEIRO ### */}
            <G.ButtonMenu 
              onClick={() => setSubMenu3(subMenu3 === false ? true : false) + setSubMenu2(false) + setSubMenu4(false)}
              style={{ backgroundColor:  user.adm !== 1 ? '' : subMenu3 ? '#EDEDFF' : '' }}
            >
              {/* <LocalAtmIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
              <G.SmallTitle >Financeiro</G.SmallTitle>
              {user.adm !== 1 ? <LockIcon fontSize="small" /> : subMenu3 ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}

              {!isActive && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', color: '#888' }}>
                  <LocalAtmIcon style={{ marginRight: '8px' }}/>
                  <G.SmallTitle style={{ marginRight: '2px' }}>Financeiro</G.SmallTitle>
                  <LockIcon fontSize="small" />
                </div>
              }
              {isActive && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                  <LocalAtmIcon style={{ marginRight: '8px' }}/>
                  <G.SmallTitle style={{ marginRight: '2px' }}>Financeiro</G.SmallTitle>
                  {subMenu3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              }

              {/* ### SUBMENU – MENU #3 – FINANCEIRO ### */}
              {(isActive && subMenu3) && 
                <G.PopUpAreaMenu style={{ alignItems: 'center', padding: '12px 16px', position: 'absolute', top: '-8px' }}>

                  <G.ButtonMenu style={{ marginBottom: '6px' }} onClick={() => MenuItemClick('/FinancialDashboard')}>
                    <G.TextButtonSubMenu>
                      <DashboardIcon style={{ marginRight: '8px', transform: 'rotate(90deg)' }}/>
                      <G.SmallTitle>Dashboard</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Receipts')}>
                    <G.TextButtonSubMenu>
                      <LoginIcon style={{ marginRight: '8px', transform: 'rotate(90deg)' }}/>
                      <G.SmallTitle>Recebimentos</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Payments')}>
                    <G.TextButtonSubMenu>
                      <LogoutIcon style={{ marginRight: '8px', transform: 'rotate(270deg)' }}/>
                      <G.SmallTitle>Pagamentos</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/ReceiptCategories')}>
                    <G.TextButtonSubMenu>
                      <LoginIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Categ. Recebimentos</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/PaymentCategories')}>
                    <G.TextButtonSubMenu>
                      <LogoutIcon style={{ marginRight: '8px', transform: 'rotate(180deg)' }}/>
                      <G.SmallTitle>Categ. Pagamentos</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Banks')}>
                    <G.TextButtonSubMenu>
                      <AccountBalanceIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Bancos</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} />

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/BankAccounts')}>
                    <G.TextButtonSubMenu>
                      <AccountBalanceWalletIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Contas</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '1px dotted #999' }} /> */}

                </G.PopUpAreaMenu>
              }
            </G.ButtonMenu>

            {/* ### MENU #4 – OUTROS ### */}
            <G.ButtonMenu 
              onClick={() => setSubMenu4(subMenu4 === false ? true : false) + setSubMenu2(false) + setSubMenu3(false)}
              style={{ backgroundColor:  user.adm !== 1 ? '' : subMenu4 ? '#EDEDFF' : ''}}
            >
              {/* <LocalAtmIcon sx={{ borderBottom: '1px dotted #333' }} style={{ marginRight: '8px' }}/>
              <G.SmallTitle >Financeiro</G.SmallTitle>
              {user.adm !== 1 ? <LockIcon fontSize="small" /> : subMenu4 ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}

              {!isActive && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center', color: '#888' }}>
                  <ViewWeekIcon style={{ marginRight: '8px' }}/>
                  <G.SmallTitle style={{ marginRight: '2px' }}>Outros</G.SmallTitle>
                  <LockIcon fontSize="small" />
                </div>
              }
              {isActive && 
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
                  <ViewWeekIcon style={{ marginRight: '8px' }}/>
                  <G.SmallTitle style={{ marginRight: '2px' }}>Outros</G.SmallTitle>
                  {subMenu4 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
              }

              {/* ### SUBMENU – MENU #4 – OUTROS ### */}
              {(isActive && subMenu4) && 
                <G.PopUpAreaMenu style={{ alignItems: 'center', padding: '12px 16px', position: 'absolute', top: '-8px' }}>
                  {/* <G.ButtonMenu style={{ marginBottom: '6px' }} onClick={() => MenuItemClick('/MyCompany')}>
                    <G.TextButtonSubMenu>
                      <StoreIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Minha Empresa</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} /> */}

                  <G.ButtonMenu style={{ marginTop: '6px', marginBottom: '6px' }} onClick={() => MenuItemClick('/Integration')}>
                    <G.TextButtonSubMenu>
                      <IntegrationInstructionsIcon style={{ marginRight: '8px' }}/>
                      <G.SmallTitle>Integração</G.SmallTitle>
                    </G.TextButtonSubMenu>
                  </G.ButtonMenu>
                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '0px', border: '0.05rem dotted #999' }} /> */}
                </G.PopUpAreaMenu>
              }
            </G.ButtonMenu>

          </G.Menu>

        </G.AreaMenu>
      </div>


      {modal &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ padding: '15px', backgroundColor: '#e9e9e9'}}>

            {step === 1 &&
              <M.ModalBody style={{ width: '700px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <G.BoxBody style={{color: '#fff', flexDirection: 'column'}}>
                  <C.Info >Criar um novo processo do tipo:</C.Info>
                  <C.Button onClick={() => StepClick(2, 1)}>
                    <C.IconArea style={{ alignItems: 'end' }}><KeyboardArrowRightIcon fontSize={'large'}/></C.IconArea>
                    <G.DistacInfoText>Perícia</G.DistacInfoText>
                  </C.Button>
                  <C.Button onClick={() => StepClick(2, 2)}>
                    <C.IconArea><KeyboardArrowRightIcon fontSize={'large'}/></C.IconArea>
                    <G.DistacInfoText>Reclamante</G.DistacInfoText>
                  </C.Button>
                  <C.Button onClick={() => StepClick(2, 3)}>
                    <C.IconArea><KeyboardArrowRightIcon fontSize={'large'}/></C.IconArea>
                    <G.DistacInfoText>Reclamada</G.DistacInfoText>
                  </C.Button>
                </G.BoxBody>
              </M.ModalBody>
            }

            {step === 2 &&
              <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'center'}}>
                <G.BoxBody style={{ margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                  <G.FormInput value={type} type="hidden" {...register("tipo")}/>
                  <G.FormInputArea  style={{width: '40%'}}> 
                    <G.FormInputLabel style={{ borderLeft: '3px solid blue'}}>Identificação</G.FormInputLabel><G.FormInput {...register("identificacao", { required: "Please enter your first name." })}/>
                  </G.FormInputArea>
                  <G.FormInputArea style={{width: '27%'}}>
                    <G.FormInputLabel>Processo</G.FormInputLabel><G.FormInput  {...register("processo")}/>                      
                  </G.FormInputArea>
                  <G.FormInputArea style={{width: '27%'}}>
                    <G.FormInputLabel>Junta</G.FormInputLabel><G.FormInput {...register("junta")}/>                
                  </G.FormInputArea>
                  <G.FormInputArea style={{width: '48%'}}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid green'}}>Reclamante</G.FormInputLabel><G.FormInput {...register("reclamante")}/>                      
                  </G.FormInputArea>
                  <G.FormInputArea style={{width: '48%',}}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid orange'}}>Reclamada</G.FormInputLabel><G.FormInput  {...register("reclamada")}/>                      
                  </G.FormInputArea>
                  <G.FormInputArea  style={{width: '20%'}}>
                    <G.FormInputLabel>Entrada</G.FormInputLabel><G.FormInput type="date" {...register("entrada")}/>
                  </G.FormInputArea>      
                  <G.FormInputArea style={{width: '20%'}}>
                    <G.FormInputLabel>Prazo</G.FormInputLabel><G.FormInput type="date" {...register("prazo")}/>                      
                  </G.FormInputArea>         
                  <G.FormInputArea  style={{width: '27%'}}>
                    <G.FormInputLabel>Calculista</G.FormInputLabel>
                    <G.FormSelect {...register("calculista")} onMouseOver={() => GetSelect(3)}>
                      <option>Selecione o Calculista</option>
                      {users.map(u => (
                        <option key={u.id_usuario} value={u.nome}>{u.nome}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea> 

                  {type === 1 &&
                    <G.FormInputArea style={{width: '27%',}}>
                      <G.FormInputLabel>Perito Responsável</G.FormInputLabel><G.FormInput{...register("perito")}/>                      
                    </G.FormInputArea>
                  }

                  {(type === 2 || type === 3) &&
                    <G.FormInputArea style={{width: '48%'}}>
                      <G.FormInputLabel>Advogado</G.FormInputLabel>
                      <G.FormSelect {...register("codadv")}  onMouseOver={() => GetSelect(2)}>
                        <option>Selecione um Advogado</option>
                        {lawyers.map(a => (
                          <option key={a.codadv}  value={a.codadv}>{a.nomeadv}</option>
                        ))}
                      </G.FormSelect>                      
                    </G.FormInputArea>
                  }

                  {type === 3 &&
                    <G.FormInputArea  style={{width: '48%'}}>
                      <G.FormInputLabel>Empresa</G.FormInputLabel>
                      <G.FormSelect {...register("cod_empresa")} onMouseOver={() => GetSelect(1)}>
                        <option>Selecione a Empresa</option>
                        {company.map(c => (
                          <option key={c.cod_empresa} value={c.cod_empresa}>{c.empresa}</option>
                        ))}
                      </G.FormSelect>
                    </G.FormInputArea>      
                  }   
                </G.BoxBody>
              </M.ModalBody>
            }

            {step === 3 &&
              <M.ModalBody style={{ width: '700px', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <G.BoxBody style={{color: '#fff', flexDirection: 'column'}}>

                  <C.Info >Selecione a fase</C.Info>
                  <G.FormInputArea  style={{width: '100%'}}>
                    <G.FormInputLabel>Fase</G.FormInputLabel>
                    <G.FormSelect   onChange={e => GetAtvs(e.target.value)} onMouseOver={() => GetSelect(4)}>
                      <option>Selecione a Fase a adicionar</option>
                      {phaseType.map(p => (
                        <option key={p.codfase} value={p.codfase}>{p.fase}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>  
                  {atvs !== '' &&
                    <C.Info >  
                      {atvs.map((item, key)=>(
                        <G.FormInputArea key={key} style={{}}>
                          <G.FormInputLabel style={{backgroundColor: '#F9FDFC', width: '100%'}}>{item.nome_atividade}</G.FormInputLabel>
                        </G.FormInputArea>
                      ))}
                    </C.Info>
                  }
                  
                  {atvs.length === 0 &&
                  <G.Text style={{ color: '#E95839' }}>(*) Selecione uma Fase que possua ao menos uma Atividade cadastrada.</G.Text>
                  }

                </G.BoxBody>
              </M.ModalBody>
            }
               
            <M.ModalFooter style={{ flexDirection: 'row', height: '20px', paddingTop: '20px' }}>
              <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick()}>Fechar</G.Button>
                {step === 2 &&
                  <G.Button style={{backgroundColor: '#1E90FF'}} onClick={() => setStep(3)}>Fase</G.Button>
                }
                {(step === 3 && atvs.length !== 0) &&
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Salvar</G.Button>
                }
            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }

    </G.Container>
  );
}
