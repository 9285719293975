import React from 'react';


export const VerticalBar = () => {

	return (

		<hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 2px', border: '0.04rem solid #333', borderRadius: '4px' }} />

	);

}
