import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';
import moment from 'moment'

import * as C from './styled'
import * as G from '../../../styles/global'
import * as M from '../../../styles/modal'

import Api from '../../../services/api'
import FinancialApi from '../../../services/financialApi'
import { UserContext } from '../../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import ConstructionIcon from '@mui/icons-material/Construction';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import WarningIcon from '@mui/icons-material/Warning';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';


/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';





export const Suppliers = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState(true)
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  
  const [entityType, setEntityType] = useState([])
  const [taxpayerType, setTaxpayerType] = useState([])
  const [selectedEntityType, setSelectedEntityType] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)

  const [receitaWS, setReceitaWS] = useState([])
  
  const [empty, setEmpty] = useState(null)
  const [stateErrors, setStateErros] = useState('');



  /// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')


  
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
        return data.entity_type_acronym.toLowerCase().search(value) !== -1 
            || data.trade_name.toLowerCase().search(value) !== -1 
            || data.address_city.toLowerCase().search(value) !== -1 
            || data.address_state_acronym.toLowerCase().search(value) !== -1 
            || data.address_country.toLowerCase().search(value) !== -1 
            || data.contact_phone.toLowerCase().search(value) !== -1 
        })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }

  
  /// MASKS ////////////////////////////////////////////////////////////////////

  const [cnpjNumber, setCnpjNumber] = useState('')
  const onChangeCnpj = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['999.999.999/9999-99']);
    setCnpjNumber(unMask(maskedValue));
  }

  const [cpfNumber, setCpfNumber] = useState('')
  const onChangeCpf = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['999.999.999-99']);
    setCpfNumber(unMask(maskedValue));
  }

  const [rgNumber, setRgNumber] = useState('')
  const onChangeRg = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99.999.999-9']);
    setRgNumber(unMask(maskedValue));
  }

  const [cepNumber, setCepNumber] = useState('')
  const onChangeCep = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99999-999']);
    setCepNumber(unMask(maskedValue));
  }

  const [phoneNumber, setPhoneNumber] = useState('')
  const onChangePhone = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9999-9999']);
    setPhoneNumber(unMask(maskedValue));
  }

  const [cellNumber, setCellNumber] = useState('')
  const onChangeCell = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9 9999-9999']);
    setCellNumber(unMask(maskedValue));
  }



  /// FUNCTIONS ////////////////////////////////////////////////////////////////

  const GetReceitaWS = async () => {
    // console.log("GetReceitaWS > CNPJ", cnpjNumber);
    setLoading(true)
    let rws = await FinancialApi.getReceitaWS((cnpjNumber).toString());
    setReceitaWS(rws);
    // console.log("GetReceitaWS > rws", rws);
    setLoading(false)
  }

  const loadBases =  async () => {
    let res1 = await FinancialApi.getEntityType();
    setEntityType(res1);
    let res2 = await FinancialApi.getTaxpayerType();
    setTaxpayerType(res2);
  }
 

  const GetSupplier = async () => {
    setEmpty(false)
    setLoading(true)
    let res = await FinancialApi.getSupplier();
    if (res.length > 0) {
      setEmpty(false)
      setList(res)
      setFilteredList(res)
    } else {
        setEmpty(true)
    }
    setLoading(false)
  }


  const onSubmit = async (data) => {
    data["cnpj"] = cnpjNumber
    data["cpf"] = cpfNumber
    data["rg"] = rgNumber
    data["address_zip_code"] = cepNumber
    data["contact_phone"] = phoneNumber
    data["contact_cell_phone"] = cellNumber

    // if (
    //   (selectedEntityType === '1' && (cpfNumber === '' || cpfNumber === null || rgNumber === '' || rgNumber === null || cellNumber === '' || cellNumber === null))
    //   ||
    //   (selectedEntityType === '2' && (cnpjNumber === '' || cnpjNumber === null || phoneNumber === '' || phoneNumber === null))
    //   || 
    //   (cepNumber === '' || cepNumber === null)
    // ) {setStateErros(true)}
    // else {
    //   setStateErros(false)

    //   switch (modalType) {
    //     case 1:
    //       const userAdd = ({
    //         created_by: user.id,
    //         changed_by: user.id
    //       })
    //       const add = ({...data, ...userAdd})
    //       let res1 = await FinancialApi.postSupplier(add)
    //       break;

    //     case 2:
    //       const userUpd = ({
    //         changed_by: user.id
    //       })
    //       const upd = ({...data, ...userUpd})
    //       let res2 = await FinancialApi.postSupplier(upd)
    //       break;

    //     default:
    //       break;
    //   }

    //   CloseModalClick()

    // }

    switch (modalType) {
      case 1:
        const userAdd = ({
          created_by: user.id,
          changed_by: user.id
        })
        const add = ({...data, ...userAdd})
        let res1 = await FinancialApi.postSupplier(add)
        break;

      case 2:
        const userUpd = ({
          changed_by: user.id
        })
        const upd = ({...data, ...userUpd})
        let res2 = await FinancialApi.postSupplier(upd)
        break;

      default:
        break;
    }

    CloseModalClick()


  };


  const DelSupplier = async (id) => {
    let res = await FinancialApi.delSupplier(id);
    GetSupplier();
  }


  const ActSupplier = async (id, estado) => {
    const data = ({
      id: id, 
      state: estado, 
      changed_by: user.id
    })
    let res = await FinancialApi.postSupplier(data);
    GetSupplier();
  }


  const AddClick = () => {
    setModalType(1)
    loadBases()
    setModal(true)
  }  


  const EditClick = (key) => {
    setSelectedKey([key])

    setCnpjNumber(filteredList[key].cnpj);
    setCpfNumber(filteredList[key].cpf);
    setRgNumber(filteredList[key].rg);
    setCepNumber(filteredList[key].address_zip_code);
    setPhoneNumber(filteredList[key].contact_phone);
    setCellNumber(filteredList[key].contact_cell_phone);

    setModalType(2)
    loadBases()
    setModal(true)
  }


  const DelClick = async (key) => {
    setSelectedKey([key])
    setModalType(3)
    setModal(true)
  }


  const ActClick = (key) => {
    setSelectedKey([key])
    setModalType(4)
    setModal(true)
  }


  const CloseModalClick = () => {
    setLoading(true)
    setSelectedKey('')
    setAlertError('')
    GetSupplier()
    setCnpjNumber('')
    setCpfNumber('')
    setRgNumber('')
    setCepNumber('')
    setPhoneNumber('')
    setCellNumber('')
    setStateErros(false)
    setModal(false)
    setSelectedEntityType('')
    setModalType('')
    reset()
    setLoading(false)
  }


  const CloseModalClick2 = () => {
    setLoading(true)
    setSelectedKey('')
    setAlertError('')
    setCnpjNumber('')
    setCpfNumber('')
    setRgNumber('')
    setCepNumber('')
    setPhoneNumber('')
    setCellNumber('')
    setStateErros(false)
    setModal(false)
    setSelectedEntityType('')
    setModalType('')
    reset()
    setLoading(false)
  }





/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    GetSupplier();
  }, [state]);



/// CONSOLE LOG ////////////////////////////////////////////////////////////////
  console.log("CNPJ NUMBER", cnpjNumber)
  console.log("RECEITA WS", receitaWS)


  



////////////////////////////////////////////////////////////////////////////////





  return (
    <G.Container>

      <G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <ConstructionIcon style={{ fontSize: '32px', margin: '0px 0px 0px 0px' }} />
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <G.MainTitle style={{ fontSize: 'var(--font-size-title)' }}>Fornecedores</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>
          
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
          <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
            <G.SearchIconArea>
              <SearchIcon />
            </G.SearchIconArea>
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SearchArea>        
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>
      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{ justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column' }}>
            <ErrorOutlineRoundedIcon style={{ fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{ fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>

              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px' }}>
                  <G.FieldArea size={'100px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('entity_type_id')}><G.DistacInfoText>Tipo</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'entity_type_id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'248px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('company_name')}><G.DistacInfoText>Nome</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'company_name') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'150px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('address_city')}><G.DistacInfoText>Cidade</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'address_city') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'40px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('address_state_acronym')}><G.DistacInfoText>UF</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'address_state_acronym') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'150px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('address_country')}><G.DistacInfoText>País</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'address_country') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('contact_phone')}><G.DistacInfoText>Telefone</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'contact_phone') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              {(loading === true && filteredList.length === 0 ) &&  
                <G.Loading2 src={"/loading.svg"} />
              }

              {(loading === false && filteredList.length > 0) &&
                <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                  {/* {(loading === false && filteredList.length === 0) && 
                    <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                      *** Não existem itens a exibir. ***
                    </G.Text>
                  } */}
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key}>
                        <G.FieldArea size={'100px'}><G.Text>{item.entity_type_acronym}</G.Text></G.FieldArea>
                        <G.FieldArea style={{justifyContent: 'start'}} size={'248px'}><G.Text>{item.trade_name}</G.Text></G.FieldArea>
                        <G.FieldArea size={'150px'}><G.Text>{item.address_city}</G.Text></G.FieldArea>
                        <G.FieldArea size={'40px'}><G.Text>{item.address_state_acronym}</G.Text></G.FieldArea>
                        <G.FieldArea size={'150px'}><G.Text>{item.address_country}</G.Text></G.FieldArea>
                        <G.FieldArea size={'120px'}><G.Text>{mask(item.contact_phone, ['(99) 9999-9999'])}</G.Text></G.FieldArea>
                        <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                          <G.IconButton onClick={() => EditClick(key) + setSelectedEntityType((filteredList[key].entity_type_id).toString())} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}>
                            <EditIcon fontSize="small" />
                          </G.IconButton>
                          <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.state === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>
                            {item.state === 0 ? <CheckIcon fontSize="small"/> : item.state === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}
                          </G.IconButton>
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}>
                            <DeleteIcon fontSize="small"/>
                          </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                </G.TableBody>
              }
            </G.Box>
          </>
        }
      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center' }}>

            <M.ModalHeader 
              style={{ 
                backgroundColor
                  : modalType === 1 ? '#BDF5D6' 
                  : modalType === 2 ? '#C8E4FF' 
                  : modalType === 3 ? '#FCAC9B' 
                  : (filteredList[selectedKey].state === 0 ? '#BDF5D6' : '#ccc'), 
                justifyContent: 'space-between', 
                bordeRadius: '15px' 
              }}
            >
              <C.ModalTitle>{modalType === 1 ? 'Adicionar Fornecedor' : modalType === 2 ? 'Editar Fornecedor' : modalType === 3 ? 'Excluir Fornecedor' : (filteredList[selectedKey].state === 0 ? 'Ativar Fornecedor' : 'Desativar Fornecedor')}</C.ModalTitle>
                <G.InfoArea>
                  <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
                </G.InfoArea>
            </M.ModalHeader>

            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'start', marginTop: '5px'}}>

              {(modalType === 2 || modalType === 3) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" readOnly={true} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].id} {...register("id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBodyScroll style={{ display: 'flex', margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                  <G.FormInputArea style={{ width: '220px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                      Tipo
                    </G.FormInputLabel>
                    <G.FormSelect 
                      {...register("entity_type_id")}
                      onChange={(e) => setSelectedEntityType(e.target.value)}
                    >
                      <option autoFocus disabled selected value={modalType === 1 ? '' : filteredList[selectedKey].entity_type_id}>
                        {modalType === 1 ? 'Selecione' : filteredList[selectedKey].entity_type_name}
                      </option>
                      {entityType.map(i => (
                        <option key={i.id} value={i.id}>{i.name}</option>
                      ))}
                    </G.FormSelect>
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '690px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Nome
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].trade_name} {...register("trade_name")} />
                  </G.FormInputArea>

                  {/* TIPO ENTIDADE – PESSOA JURÍDICA */}
                  {selectedEntityType === '2' && 
                    <>
                      <G.FormInputArea style={{ width: '240px', justifyContent: 'center', alignItems: 'center' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                          CNPJ
                        </G.FormInputLabel>
                        {/* <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].cnpj} {...register("cnpj", { required: selectedEntityType === '2' ? true : false }, { setValueAs: v => v === '' ? null : v })} /> */}
                        <G.FormInput 
                          type='text'
                          name='cnpj'
                          placeholder=''
                          value={
                            cnpjNumber === null 
                            ? '' 
                            : mask(cnpjNumber, ['999.999.999/9999-99'])
                          }
                          onChange={ selectedEntityType === '2' ? onChangeCnpj : setCnpjNumber(null)}

                          // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].cnpj} 
                          // {...register("cnpj", { required: selectedEntityType === '2' ? true : false }, { setValueAs: v => v === '' ? null : v })} 
                        />
                        <G.PlayIconButton 
                          style={{ width: '32px', height: '26px', margin: '0px 2px 0px 0px' }}
                          onClick={GetReceitaWS}
                        >
                          <SearchRoundedIcon />
                        </G.PlayIconButton>
                      </G.FormInputArea>
                      {/* <G.PlayIconButton style={{ width: '32px', height: '28px', border: '1px solid #ccc', margin: '8px 8px 8px 0px' }}>
                        <SearchRoundedIcon />
                      </G.PlayIconButton> */}
                      


                      <G.FormInputArea style={{ width: '690px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                          Razão Social
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].company_name} {...register("company_name", { required: selectedEntityType === '2' ? true : false }, { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '240px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Tipo Contribuinte
                        </G.FormInputLabel>
                          <G.FormSelect {...register("taxpayer_type_id", { setValueAs: v => v === '' ? null : v })}>
                            <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].taxpayer_type_id}>
                              {modalType === 1 ? 'Selecione' : filteredList[selectedKey].taxpayer_type_name}
                            </option>
                            {taxpayerType.map(i => (
                              <option key={i.id} value={i.id}>{i.name}</option>
                            ))}
                          </G.FormSelect>
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '240px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Inscr. Estadual
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].state_tax_id_number} {...register("state_tax_id_number", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '222px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Inscr. Municipal
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].city_tax_id_number} {...register("city_tax_id_number", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '222px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Suframa
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].suframa} {...register("suframa", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>
                    </>
                  }

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}
                  
                  {/* TIPO ENTIDADE – PESSOA FÍSICA */}
                  {selectedEntityType === '1' && 
                    <>
                      <G.FormInputArea style={{ width: '260px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                          CPF
                        </G.FormInputLabel>
                        <G.FormInput 
                          type='text'
                          name='cpf'
                          placeholder=''
                          value={
                            cpfNumber === null 
                            ? '' 
                            : mask(cpfNumber, ['999.999.999-99'])
                          }
                          onChange={selectedEntityType === '1' ? onChangeCpf : setCpfNumber(null)}
                          // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].cpf} 
                          // {...register("cpf", { required: selectedEntityType === '1' ? true : false }, { setValueAs: v => v === '' ? null : v })} 
                        />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '260px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                          RG
                        </G.FormInputLabel>
                        <G.FormInput 
                          type='text'
                          name='rg'
                          placeholder=''
                          value={
                            rgNumber === null 
                            ? '' 
                            : mask(rgNumber, ['99.999.999-9'])
                          }
                          onChange={onChangeRg}
                          // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].rg} 
                          // {...register("rg", { setValueAs: v => v === '' ? null : v })} 
                        />
                      </G.FormInputArea>
                    </>
                  }

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  {/* TIPO ENTIDADE – ESTRANGEIRA || TIPO ENTIDADE – OUTRA */}
                  {(selectedEntityType === '3') && 
                    <>
                      <G.FormInputArea style={{ width: '390px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                        Tipo Documento
                        </G.FormInputLabel>
                        <G.FormInput 
                          defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].document_type} 
                          {...register("document_type", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '440px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #777' }}>
                          Código Documento
                        </G.FormInputLabel>
                        <G.FormInput 
                          defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].document_code} 
                          {...register("document_code", { setValueAs: v => v === '' ? null : v })} />
                      </G.FormInputArea>
                    </>
                  }

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <G.FormInputArea style={{ width: '592px' }}>
                    <G.FormInputLabel>
                      Endereço
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].address} {...register("address", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '360px' }}>
                    <G.FormInputLabel>
                      Complemento
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].address_complement} {...register("address_complement", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '480px' }}>
                    <G.FormInputLabel>
                      Bairro
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].address_district} {...register("address_district", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '180px' }}>
                    <G.FormInputLabel>
                      CEP
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='cep'
                      placeholder='Código Postal'
                      value={
                        cepNumber === null 
                        ? '' 
                        : mask(cepNumber, ['99999-999'])
                      }
                      onChange={onChangeCep}
                      // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].address_zip_code} 
                      // {...register("address_zip_code", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '480px' }}>
                    <G.FormInputLabel>
                      Cidade
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].address_city} {...register("address_city", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '120px' }}>
                    <G.FormInputLabel>
                      UF
                    </G.FormInputLabel>
                    <G.FormInput maxLength={2} defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].address_state_acronym} {...register("address_state_acronym", { setValueAs: v => v === '' ? null : v.toUpperCase() })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '340px' }}>
                    <G.FormInputLabel>
                      País
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].address_country} {...register("address_country", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <G.FormInputArea style={{ width: '344px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Contato
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].contact_person_name} {...register("contact_person_name", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '220px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Telefone
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='telefone'
                      placeholder=''
                      value={
                        phoneNumber === null 
                        ? '' 
                        : mask(phoneNumber, ['(99) 9999-9999'])
                      }
                      onChange={onChangePhone}
                      // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].contact_phone} 
                      // {...register("contact_phone", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '140px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Ramal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].contact_phone_extension} {...register("contact_phone_extension", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '220px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Celular
                    </G.FormInputLabel>
                    <G.FormInput
                      type='text'
                      name='celular'
                      placeholder=''
                      value={
                        cellNumber === null 
                        ? '' 
                        : mask(cellNumber, ['(99) 9 9999-9999'])
                      }
                      onChange={onChangeCell} 
                      // defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].contact_cell_phone} 
                      // {...register("contact_cell_phone", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '300px' }}>
                    <G.FormInputLabel>
                      E-mail
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].contact_email} {...register("contact_email", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '340px' }}>
                    <G.FormInputLabel>
                      Web Site
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].website} {...register("website", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  {/* <G.FormInputArea style={{ width: '300px' }}>
                    <G.FormInputLabel>
                      Responsável
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].fncdr_responsavel} {...register("fncdr_responsavel", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea> */}

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '16px 0px 16px -8px' }}>
                    {(Object.keys(errors).length > 0 || stateErrors) &&  
                      <div role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '92%', padding: '7px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                        <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}</div>
                        <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                      </div>
                    }
                  </div>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '10px', marginBottom: '10px' }}>
                    <G.FormInputLabel style={{ width: '936px', justifyContent: 'flex-start', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                      Observação
                    </G.FormInputLabel>
                    <G.TextArea 
                      {...register("note", { setValueAs: v => v === '' ? null : v })} 
                      style={{ width: '916px', height: '150px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                    >
                      {modalType === 1 ? '' :  filteredList[selectedKey].note}
                    </G.TextArea>
                  </G.FieldArea>

                </G.BoxBodyScroll>
              }
            </M.ModalBody>
            
            

            {(modalType === 3) && 
              <>
                <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                  <G.Text style={{ margin: '10px 0px' }}>
                    Deseja excluir o Fornecedor:
                  </G.Text>
                  <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                    <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                      {filteredList[selectedKey].company_name}
                    </G.FormInputLabel>
                  </G.FormInputArea>
                </M.ModalBody>
              </>
            }

            {(modalType === 4) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja {filteredList[selectedKey].state === 0 ? 'ativar' : 'desativar'} o Fornecedor:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].company_name}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2) &&
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelSupplier(filteredList[selectedKey].id) + CloseModalClick()}>Sim</G.Button>
                </>
              }
              {modalType === 4 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActSupplier(filteredList[selectedKey].id, filteredList[selectedKey].state === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                </>
              }

            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }
    </G.Container>
  );
}

