import React from 'react';

import * as G from '../../styles/global'

import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';

export const PopUpUsers = ({user, type}) => {


    return (
       <div style={{maxWidth: '1px', maxHeight: '1px'}}>
        <G.PopUpAreaFlex style={{width: '150px', height: '20px', padding: '12px', justifyContent: 'space-between', marginTop: '-15px', flexDirection: 'row', alignItems: 'center'}}>
         <div>
           <G.Text style={{fontSize: '11px', marginLeft: '5px', fontWeight: 'bold', color: '#777'}}>{type === 1 ? 'Proprietário:' : 'Responsável'}</G.Text> 
           <G.Text style={{fontSize: '12px', marginLeft: '5px', fontWeight: 'bold', color: '#000'}}>{user}</G.Text>       
        </div>
        <SwapHorizontalCircleIcon style={{fontSize: '30px'}}/>   
        </G.PopUpAreaFlex>
       </div>
    );
}
