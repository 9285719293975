import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import ReactECharts from 'echarts-for-react';
import moment from "moment";
import 'moment/locale/pt-br';

import * as C from './styled'
import * as G from '../../../styles/global'
import * as M from '../../../styles/modal'

import Api from '../../../services/api'
import FinancialApi from '../../../services/financialApi'
import { UserContext } from '../../../contexts/UserContext';

import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TaskIcon from '@mui/icons-material/Task';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentIcon from '@mui/icons-material/Payment';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import ArchiveIcon from '@mui/icons-material/Archive';
import CopyrightIcon from '@mui/icons-material/Copyright';
import AccessTimeIcon from '@mui/icons-material/AccessTime';



export const FinancialDashboard = () => {

  const navigate = useNavigate();

  const { state: user } = useContext(UserContext)

  moment.locale('pt-br')

  var now = new Date()
  var day = now.getDate()
  var month = now.getMonth() + 1
  var year = now.getFullYear()


  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([])
  const [switchButton, setSwitchButton] = useState(false)
  const [state, setState] = useState(false)
  const [emptyWait, setEmptyWait] = useState([])
  const [emptySche, setEmptySche] = useState([])
  const [news, setNews] = useState([])
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const [showReply, setShowReply] = useState(false)

  const [processPay, setProcessPay] = useState([]);
  const [processPaySum, setProcessPaySum] = useState([]);

  const [process, setProcess] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState('')
  const [processSum, setProcessSum] = useState([]);
  const [processArchive, setProcessArchive] = useState([]);
  const [processArchiveSum, setProcessArchiveSum] = useState([]);

  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState('')
  const [ticketsSum, setTicketsSum] = useState([]);
  const [ticketsArchive, setTicketsArchive] = useState([]);
  const [ticketsSumArchive, setTicketsSumArchive] = useState([]);





  const option2 = {
    tooltip: { trigger: 'item' },
    legend: {
      top: '5%',
      left: 'center'
    },
    series: [
      {
        type: 'pie',
        color: ['#F15068', '#51B476', '#84b6f4'  ], 
        radius: ['32%', '60%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 8,
          borderColor: '#fff',
          borderWidth: 4
        },
        
        label: {
          show: false,
          position: 'outside'
        },

        data: process
      },
    ]
  };


  const option3 = {
    tooltip: {},
    legend: {
      top: '5%',
      left: 'center'
    },
    dataset: {
      source: [
        ['month', 'Pericia', 'Reclamante', 'Reclamada'],
        ['Janeiro', 50.50, 100.50, 200.50],
        ['Fevereiro', 50.50, 100.50, 200.50],
        ['Março', 50.50, 100.50, 200.50],
      ]
    },
    xAxis: { type: 'category' },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: [
      { type: 'bar', 
        color: '#F15068', 
        label: {
          show: true, 
          position: 'outside'
        }
      }, 
      { type: 'bar', 
        color: '#51B476', 
        label: {
          show: true, 
          position: 'outside'
        }
      }, 
      { type: 'bar', 
        color: '#84b6f4', 
        label: {
          show: true, 
          position: 'outside'
        } 
      },
    ]
  };



  const GetProcessInfoData = async () => {
    let [pericia, reclamante, reclamada] = await  Promise.all([Api.getProcessInfoData('1'), Api.getProcessInfoData('2'), Api.getProcessInfoData('3')]) 
    let processStates = ([ 
      {'name': 'Pericia', 'value': pericia[0].process === 0 ? null : pericia[0].process},
      {'name': 'Reclamante', 'value': reclamante[0].process === 0 ? null : reclamante[0].process},
      {'name': 'Reclamada', 'value': reclamada[0].process === 0 ? null : reclamada[0].process}                                                                         
    ])
    setProcessSum(pericia[0].process + reclamante[0].process + reclamada[0].process) 
    setProcess(processStates)
  }


  const GetProcessPay = async () => {
    let [pericia, reclamante, reclamada] = await  Promise.all([Api.getProcessPay('1', month), Api.getProcessPay('2', month), Api.getProcessPay('3', month)]) 
    let processPayResult = ([ 
      {'name': 'Pericia', 'value': pericia[0].total, itemStyle: { color: '#F15068'}},
      {'name': 'Reclamante', 'value': reclamante[0].total, itemStyle: { color: '#51B476'}},
      {'name': 'Reclamada', 'value': reclamada[0].total, itemStyle: { color: '#84b6f4' }},                                                                     
    ])
      setProcessPaySum((Number(pericia[0].total) + Number(reclamante[0].total) + Number(reclamada[0].total)).toLocaleString('pt-BR')) 
      setProcessPay(processPayResult)
      //setProcess(processStates)
  }


  const GetProcessAlert = async () => {
    let res = await Api.getProcessAlert()
    setEmptyWait(res.sort(function(a, b){return new Date(a.fase_proc_data_pazo) - new Date(b.fase_proc_data_pazo)}))
  }


  const GetOrderPay = async () => {
    let res = await Api.getOrderPay();
    setEmptySche(res)
  }




/// USEEFECT //////////////////////////

  useEffect(()=>{
    const interval = setInterval(() => {
      setState(state === true ? false : true)
    }, 60000)
  }, []);

  useEffect(() => {
    GetProcessAlert()
    GetOrderPay()
  }, [])

  useEffect(() => {
    GetProcessInfoData()
  }, [])

  useEffect(() => {
    GetProcessPay()
  }, [state])



/// CONSOLE ///////////////////////////







/// RETURN /////////////////////////////

  return (
    <G.Container>

      <G.Header>
        <G.HeaderTitleArea>
          <DashboardIcon fontSize='large' style={{ margin: '0px 4px 0px 0px' }} />
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 2px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <G.MainTitle style={{ fontSize: 'var(--font-size-title)', margin: '0px 0px 0px 6px' }}>
            {'Dashboard'}
          </G.MainTitle>
        </G.HeaderTitleArea>
      </G.Header>
      
      <G.Body style={{ flexDirection: 'row', flexWrap: "wrap" }}>

        <G.Card style={{ width: '500px', height: '400px', backgroundColor: '#f7f7f7' }}>
          <G.CardHeader >
            <NotificationsActiveIcon style={{ fontSize: '30px', color: '#777', marginRight: '5px' }}/>
            <G.SmallTitle>Processos com Prazo Inferior à 48h</G.SmallTitle>
          </G.CardHeader>

          <G.CardBodyScroll style={{ flexDirection: 'column', height: '96%' , marginLeft: '12px' }}>
            {emptyWait.length === 0 &&
              <G.InfoArea style={{ justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CheckCircleOutlineIcon style={{ fontSize: '140px', color: '#ccc' }}/>
              </G.InfoArea>
            }
            {emptyWait.map((item, key) => (
              <G.OptionsArea 
                key={key} 
                style={{ 
                  flexDirection: 'row', 
                  width: '96%', 
                  justifyContent: 'space-between', 
                  alignItems: 'center', 
                  backgroundColor: '#fff', 
                  padding: '5px', 
                  borderRadius: '7px', 
                  margin: '6px 0px', 
                  boxShadow: '1px 1px 3px #ccc' 
                }}
              >
                <div style={{ flexDirection: 'row', display: 'flex' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginLeft: '5px', backgroundColor: moment(item.fase_proc_data_pazo).isSameOrAfter(moment(), 'day') === true ? 'orange' : 'red' , width: '3px', height: '40px', borderRadius: '3px' }}></div>
                    <AccessTimeIcon style={{ margin: '0px 7px', fontSize: '34px', color: '#777' }}/>
                  </div>
                  <G.InfoArea style={{ flexDirection: 'column' , alignItems: 'start',  padding: '5px' }}>
                    <G.InfoArea style={{ width: '100%', justifyContent: 'start', padding: '5px' }}>
                      <G.DistacInfoText>
                      {item.proc_id.toString().padStart(6)} | {item.proc_identificacao} 
                      </G.DistacInfoText>
                    </G.InfoArea>
                    <G.DistacInfoText>
                    </G.DistacInfoText>
                    <G.InfoArea style={{ width: '100%', justifyContent: 'start', padding: '3px 3px', borderRadius: '3px', fontSize: '11px' }}>
                      <G.InfoText>
                        {moment(item.fase_proc_data_pazo).isSameOrAfter(moment(), 'day') === true ? 'Prazo expira em:' : 'Prazo expirou em:'}
                      </G.InfoText>
                      <G.InfoText style={{marginLeft: '10px'}}>
                      <strong>{new Date(item.fase_proc_data_pazo).toLocaleDateString('pt-BR')}</strong>
                      </G.InfoText>
                    </G.InfoArea>
                  </G.InfoArea>
                </div>
                <G.InfoArea>
                  <TaskIcon style={{ fontSize: '36px', color: item.proc_tipo_proc_id === 3 ? '#84b6f4' : item.proc_tipo_proc_id === 2 ? '#51B476' : item.proc_tipo_proc_id === 1 ? '#F15068' : '#333'}}/>
                </G.InfoArea>
              </G.OptionsArea>
            ))}
          </G.CardBodyScroll>
          
          {/* <G.OptionsArea></G.OptionsArea> */}
          <G.CardFooter style={{ marginBottom: '0px', background: '#f0f0f0' }}>
            <G.DistacInfoText>
              Total de Processos: {(emptyWait.length)}
            </G.DistacInfoText>
          </G.CardFooter>
        </G.Card>

        <G.Card style={{ width: '500px', height: '400px' }}>
          <G.CardHeader >
            <TaskIcon style={{ marginRight: '5px', fontSize: '30px', color: '#777' }}/>
            <G.SmallTitle>Processos Ativos</G.SmallTitle>
          </G.CardHeader>

          <ReactECharts option={option2}/>

          <G.CardFooter style={{ marginBottom: '0px', background: '#f0f0f0' }}>
            <G.DistacInfoText>
              Total de Processos: {processSum}
            </G.DistacInfoText>
          </G.CardFooter>
        </G.Card>

        <G.Card style={{ width: '500px', height: '400px', backgroundColor: '#f9f9f9' }}>
          <G.CardHeader >
            <PaymentIcon style={{ marginRight: '5px', fontSize: '30px', color: '#777' }}/>
            <G.SmallTitle>Contas a PAGAR com vecimento INFERIOR a 5 dias</G.SmallTitle>
          </G.CardHeader>

          <G.CardBodyScroll style={{ flexDirection: 'column', height: '96%' , marginLeft: '12px' }}>
            {emptySche.length === 0 &&
              <G.OptionsArea style={{ justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                <CheckCircleOutlineIcon style={{ fontSize: '140px', color: '#ccc' }}/>
              </G.OptionsArea>
            }
            {emptySche.map((item, key) => (
              <G.OptionsArea key={key}>
                <G.OptionsArea style={{ flexDirection: 'column', width: '100%', alignItems: 'start', padding: '5px', border: '1px solid #d0d0d0', borderRadius: '5px', margin: '1px', backgroundColor: '#fff' }}>
                  <G.InfoArea style={{ width: '95%', justifyContent: 'space-between', padding: '5px' }}>
                    <G.DistacInfoText  >
                      {item.pgmt_descricao} | {item.pgmt_entidade_nome}
                    </G.DistacInfoText>
                    {/* <OpenInNewIcon onClick={() => TicketClick(item.id)} style={{cursor: 'pointer'}}/> */}
                  </G.InfoArea>
                  <G.DistacInfoText>
                  </G.DistacInfoText>
                  {/* <G.InfoText  style={{padding: '10px', flexDirection: 'Column', alignItems: 'start'}}>
                    <strong>Reclamada: </strong>  { item.reclamada }
                    <strong>Reclamante: </strong>  { item.reclamante }
                    </G.InfoText> */}
                    <G.InfoArea 
                      style={{ 
                        width: '75%', 
                        justifyContent: 'start', 
                        padding: '3px 3px', 
                        marginLeft: '5px', 
                        borderLeft: moment(item.pgmt_data_vencimento).isSameOrAfter(moment(), 'day') === true ? '5px solid orange' : '5px solid red', 
                        fontSize: '11px', 
                        borderRadius: '3px', 
                        backgroundColor: '#fff', 
                      }}
                    >
                    <G.InfoText  >
                      <PaidIcon style={{ marginRight: '5px', fontSize: '24px', color: '#777' }}/>
                        {moment(item.pgmt_data_vencimento).isSameOrAfter(moment(), 'day') === true ? 'Vence no dia:' : 'Venceu no dia:' }
                      </G.InfoText>
                      <G.InfoText style={{ marginLeft: '10px' }} >
                        <strong>{new Date(item.pgmt_data_vencimento).toLocaleDateString('pt-BR')}</strong>
                    </G.InfoText>
                  </G.InfoArea>
                </G.OptionsArea>
              </G.OptionsArea>
            ))}
          </G.CardBodyScroll>

          {/* <G.OptionsArea></G.OptionsArea> */}
          <G.CardFooter style={{ marginBottom: '0px', background: '#f0f0f0' }}>
            <G.DistacInfoText>
              Total de Contas: {(emptySche.length)}
            </G.DistacInfoText>
          </G.CardFooter>

        </G.Card>
      
        <G.Card style={{ width: '500px', height: '400px' }}>
          <G.CardHeader>
            <ArchiveIcon style={{ marginRight: '5px', fontSize: '30px', color: '#777' }}/>
            <G.SmallTitle>Faturamento dos últimos 3 meses</G.SmallTitle>
          </G.CardHeader>

          <ReactECharts option={option3}/>

          <G.CardFooter style={{ marginBottom: '0px', background: '#f0f0f0' }}>
            {/* <G.DistacInfoText>
              TOTAL faturado nos ÚLTIMOS 3 meses: R$ {processPaySum}
            </G.DistacInfoText> */}
          </G.CardFooter>
        </G.Card>

      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '12px' }}>
        <p> Copyright <CopyrightIcon style={{ fontSize: '12px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p>
      </G.Footer>

    </G.Container>
  );

}
