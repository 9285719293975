import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";



import * as C from './styled'
import * as G from '../../styles/global'
import * as M from '../../styles/modal'



import Api from '../../services/api'
import { UserContext } from '../../contexts/UserContext';


import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BusinessIcon from '@mui/icons-material/Business';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import PaidIcon from '@mui/icons-material/Paid';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PlayArrow from '@mui/icons-material/PlayArrow';



export const Archive = () => {
  const navigate = useNavigate();
  const {register, handleSubmit, reset} = useForm({ mode: 'resolver' });


  const { state: user } = useContext(UserContext)

  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([])
  const [fasesList, setFasesList] = useState([])
  const [selectedProcess, setSelectedProcess] = useState('')

  const [switchButton, setSwitchButton] = useState(false)
  const [dateFilter, setDateFilter] = useState(new Date().getFullYear() - 1)
  const [empty, setEmpty] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState('')
  const [acts, setActs] = useState('')
  const [actsDetails, setActsDetails] = useState('')
  const [actsFull, setActsFull] = useState('')
  const [addFase, setAddFase] = useState(false)
  const [prevValue, setPrevValue] = useState('')

  const [valueAPIFilter, setValueAPIFilter] = useState('Selecione')
  const [filterCriteria, setFilterCriteria] = useState('')    

  const [comments, setComments] = useState('')
  const [selectedActs, setSelectedActs] = useState([])
  const [comentarios, setComentarios] = useState([]);
  const [atividades, setAtividades] = useState([]);

  const [company, setCompany] = useState([])
  const [lawyers, setLawyers] = useState([])
  const [users, setUsers] = useState([])
  const [phaseType, setPhaseType] = useState([])
  const [selectedPhase, setSelectedPhase] = useState([])
  const [atvs, setAtvs] = useState([])


  let anoM1 = new Date().getFullYear() - 1
  let anoM2 = new Date().getFullYear() - 2
  let anoM3 = new Date().getFullYear() - 3
  let anoM4 = new Date().getFullYear() - 4
  let anoM5 = new Date().getFullYear() - 5


  const GoClick = () => {
    navigate('/Process');
  }

   
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let result = [];
    result = list.filter((data) => {
      return data.reclamante.toLowerCase().search(value) !== -1
      || data.identificacao.toLowerCase().search(value) !== -1 
      || data.codproc.toString().search(value) !== -1; 
    });
    setFilteredList(result);
    }

    const GetAtividades = async (idFase) => {
      let res = await Api.getAtividades(idFase);
      if (res) {
          setAtividades(res)
      } else {
      }
       setActsFull(true);
    }

    const TicketClick = async (key, codProcess) => {
      setLoadingModal(false)
      setSelectedProcess(key)
        let res = await Api.getCompanies()
        setCompany(res)
        let res1 = await Api.getLawyers()
        setLawyers(res1)
        let res2 = await Api.getUsers()
        setUsers(res2)
        let res3 = await Api.getTiposFase()
        setPhaseType(res3)
        let res4 = await Api.getFases(codProcess);
        setFasesList(res4)
      setModal(true)
    }
  
    const GetSelect =  async (value) => {
      let res = await Api.getTiposFase()
      setPhaseType(res)
    }
  
   
    const GetAtvs =  async (value) => {
      setSelectedPhase(value)
      let res = await Api.getAtvs(value)
      setAtvs(res)
    }
  
      const AddFase = async () => {
        
        let res = await Api.addPhase(
          {
            "codfase": selectedPhase,
            "idprocesso": filteredList[selectedProcess].codproc
          })
        let phase = res.toString()
        
        let atvsData = atvs.map(i => ({...i,fk_tbfases: phase})) 
        let res2 = await Api.addAtvs(atvsData)

        let res3 = await Api.upProcess(
          {
            "estado": 1,
            "codproc": filteredList[selectedProcess].codproc
          })
  
        setAddFase('')
        setModal(false)
        setAtvs('')
    
      };
  
    const CloseModalClick = () => {
      setModal(false)
      setSelectedProcess('')
      getArchProcess();
    }


    const RefreshClick = (Year) => {
      document.getElementById('search').value = '';
      setFilteredList([])
      setDateFilter(Year)
    }



    const getArchProcess = async (value) => {
      setEmpty(false)
      setFilteredList([])
      if (valueAPIFilter === 'Selecione'){
        document.getElementById('search').value = '';
        setLoading(true)
        let res = await Api.getArchProcess(dateFilter, '' );
        if (res) {
          setEmpty(false)
          setList(res)
          setFilteredList(res)
        } else {
          setEmpty(true)
        }
        setLoading(false);

      }else{
        setLoading(true)
        let res = await Api.getArchProcessCriteria(valueAPIFilter, filterCriteria);
        if (res) {
          setEmpty(false)
          setList(res)
          setFilteredList(res)
        } else {
          setEmpty(true)
        }
        setLoading(false);

      }
   

    }

    const onSubmit = async (data) => {
      const { atividades, id_atividade, nome_atividade, estado_atividade, fk_tbfases, fk_tbusuarios, inicio, fim, duracao, durante, fase, nomeadv, nome, empresa, foto, comicao_data, ...rest } = data;
      console.log(rest)
  
      let res = await Api.upProcess(rest)
      console.log(res)
      CloseModalClick()
    }

  useEffect(() => {
    reset();
  }, [modal]);


    useEffect(()=>{

      getArchProcess();

      }, [dateFilter, switchButton]);

      console.log(filterCriteria)


    return (
        <C.Container>

            <G.Header>
              <G.HeaderTitleArea style={{ width: '240px' }}>
                <ArchiveIcon fontSize="large" style={{ margin: '0px 4px 0px 0px' }} />
                <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 2px', border: '0.04rem solid #333', borderRadius: '4px' }} />
                <G.MainTitle style={{ margin: '0px 0px 0px 6px' }}>{'Arquivados'}</G.MainTitle>
              </G.HeaderTitleArea>
              
              <G.HeaderTitleArea>
                <G.FormInputArea>
                  <G.FormSelect value={valueAPIFilter} onChange={(e) => setValueAPIFilter(e.target.value)} style={{ width: '200px', height: '40px', padding: '8px', borderRadius: '6px', backgroundColor: '#f1f1f1'}} >
                    <option>Selecione</option>
                    <option>Reclamada</option>
                    <option>Reclamante</option>
                    <option value={'codproc'}>Codigo</option>
                    <option>Processo</option>
                  </G.FormSelect>
                </G.FormInputArea>
              </G.HeaderTitleArea>

              <G.HeaderTitleArea>
                <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
                  <G.SearchIconArea>
                    <SearchIcon />
                  </G.SearchIconArea>
                    {valueAPIFilter === 'Selecione' &&
                      <G.SearchInput id='search' onChange={(event) => handleSearch(event)} ></G.SearchInput>
                    }
                    {valueAPIFilter !== 'Selecione' &&
                      <>
                        <G.SearchInput id='searchAPI' onChange={(e) => setFilterCriteria(e.target.value)} ></G.SearchInput>
                        <G.PlayIconButton style={{ height: '36px', marginLeft: '8px' }}>
                          <PlayArrowRoundedIcon fontSize="large" onClick={() => getArchProcess()} />
                        </G.PlayIconButton>
                      </>
                    }
                </G.SearchArea>
              </G.HeaderTitleArea>

              <G.HeaderTitleArea>
                <G.Button onClick={GoClick} style={{ width: '108px', backgroundColor: '#333' }}>
                  {'Voltar Ativos'}
                  <OpenInNewIcon fontSize="small" style={{ marginLeft: '7px'}} />
                </G.Button>
              </G.HeaderTitleArea> 

            </G.Header>

            <C.StateArea style={{ border: '1px solid #ccc', borderRadius: '6px', marginTop: '20px', backgroundColor: '#F9F9F9' }}>
              <ArchiveIcon fontSize="large" style={{marginRight: "7px"}} />
              <C.CardHeaderTitle style={{ justifyContent: 'center', alignItems: 'center' }}>
                {'Processos'}
                <br />
                <strong>{filteredList.length}</strong>
              </C.CardHeaderTitle>
              {valueAPIFilter === 'Selecione' &&
                <>
                  <G.Button onClick={() => RefreshClick(anoM1)} style={{ backgroundColor: dateFilter === anoM1 ? '#ffb400' : '#f9f9f9', marginLeft: '12px', width: '50px', color: '#000' }}>{new Date().getFullYear() - 1}</G.Button>      
                  <G.Button onClick={() => RefreshClick(anoM2)} style={{ backgroundColor: dateFilter === anoM2 ? '#ffb400' : '#f9f9f9', marginLeft: '12px', width: '50px', color: '#000' }}>{new Date().getFullYear() - 2}</G.Button>
                  <G.Button onClick={() => RefreshClick(anoM3)} style={{ backgroundColor: dateFilter === anoM3 ? '#ffb400' : '#f9f9f9', marginLeft: '12px', width: '50px', color: '#000' }}>{new Date().getFullYear() - 3}</G.Button>
                  <G.Button onClick={() => RefreshClick(anoM4)} style={{ backgroundColor: dateFilter === anoM4 ? '#ffb400' : '#f9f9f9', marginLeft: '12px', width: '50px', color: '#000' }}>{new Date().getFullYear() - 4}</G.Button>
                  <G.Button onClick={() => RefreshClick(anoM5)} style={{ backgroundColor: dateFilter === anoM5 ? '#ffb400' : '#f9f9f9', marginLeft: '12px', width: '50px', color: '#000' }}>{new Date().getFullYear() - 5}</G.Button>
                  <G.SearchInput value={dateFilter} onChange={e => setDateFilter(e.target.value)} style={{ border: '1px solid #ffb400', borderRadius: '6px', backgroundColor: '#f9f9f9', marginLeft: '12px', width: '40px',  color: '#000', fontSize: '13px', fontWeight: 'bold' }} />
                </>
              }                 
            </C.StateArea>
            <G.Body>
                {empty &&
                  <C.CardHeaderTitle style={{fontSize: '15px', marginTop: '150px', borderBottom: '1px solid #333', paddingBottom: '7px' }}>
                    Não existem Processos para esse filtro dentro do ano de <strong>{dateFilter}</strong> 
                  </C.CardHeaderTitle>
                }
                <C.Card >
                {loading &&  
                <C.Logo src={"/loading.svg"} />
                }  
                {filteredList.map((item, key)=>(
                  <C.CardHeader  key={key} style={{width: '100%'}}>
                    <C.CardBodyStatusText  style={{width: '30px' }} >
                      {item.tipo === 1 &&  
                      <LocalAtmIcon onClick={() => TicketClick(key, item.codproc)} style={{color: item.homologado == 0 ? '#1E90FF' : item.honor_vista + item.honor_praso == 0 ? '#E95839' : item.homologado > item.honor_vista + item.honor_praso ? '#FFB400' : '#2E8B57', cursor: 'pointer' }} fontSize="medium"/>
                      }
                    </C.CardBodyStatusText>
                      <C.CardHeaderInfo style={{ width: '210px', borderLeft: '2px solid #333'}}>
                      <C.CardHeaderNumber>Código: {item.codproc}</C.CardHeaderNumber>
                      <C.CardHeaderTitle style={{fontSize: '12px'}}>
                        { item.identificacao.length > 40 ? item.identificacao.substring(0,40)+' ...' : item.identificacao }
                      </C.CardHeaderTitle>
                      </C.CardHeaderInfo>
                      <C.CardBodyText style={{width: '290px', fontSize: '13px', borderLeft: '5px solid #2E8B57', backgroundColor: '#F5F5F5', padding: '7px 12px' }}>
                      <strong>{item.reclamante.length > 30 ? item.reclamante.substring(0,30)+' ...' : item.reclamante}</strong>
                      </C.CardBodyText>
                      <C.CardBodyText style={{width: '290px', fontSize: '13px', borderLeft: '5px solid #FCC703', backgroundColor: '#F5F5F5', padding: '7px 12px' }}>
                      <strong>{item.reclamada.length > 30 ? item.reclamada.substring(0,30)+' ...' : item.reclamada}</strong>
                      </C.CardBodyText>
                    <C.CardBodyStatusText  style={{ width: '90px', backgroundColor: '#333', color: '#fff', padding: '5px', justifyContent: 'center', display: 'flex'}}>
                    <strong>{item.tipo === 1 ? 'Perito' : item.tipo === 2 ? 'Reclamante' : item.tipo === 3 ? 'Reclamada' : 'Outro' }</strong>
                    </C.CardBodyStatusText>
                    <C.CardBodyStatusText  style={{width: '70px', fontSize: '12px', borderBottom: '2px solid #1E90FF', backgroundColor: '#F9F9F9', padding: '7px 12px', justifyContent: 'center', display: 'flex' }}>
                     {new Date(item.entrada).toLocaleDateString()}
                      </C.CardBodyStatusText>
                       <C.CardBodyStatusText  style={{width: '10px' }} >
                      <OpenInNewIcon onClick={() => TicketClick(key, item.codproc)} style={{color: '#333', cursor: 'pointer' }} fontSize="medium"/>
                    </C.CardBodyStatusText>
                  </C.CardHeader>
                ))}

                </C.Card>
                {(filteredList.length > 20 && valueAPIFilter === 'Selecione') &&    
        <C.StateArea  style={{backgroundColor: '#fff', borderLeft: '2px solid #ccc', borderRadius: '0px', marginBottom: '40px'}}>
            <ArchiveIcon  fontSize="large" style={{marginRight: "7px"}}/>
            <C.CardHeaderTitle>Número de Chamados: <strong> {filteredList.length} </strong></C.CardHeaderTitle>
              <C.Button onClick={() => RefreshClick('2023')} style={{backgroundColor: dateFilter === '2023' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2023</C.Button>    
              <C.Button onClick={() => RefreshClick('2022')} style={{backgroundColor: dateFilter === '2022' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2022</C.Button>
              <C.Button onClick={() => RefreshClick('2021')} style={{backgroundColor: dateFilter === '2021' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2021</C.Button>
              <C.Button onClick={() => RefreshClick('2020')} style={{backgroundColor: dateFilter === '2020' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2020</C.Button>
              <C.Button onClick={() => RefreshClick('2019')} style={{backgroundColor: dateFilter === '2019' ? '#ffb400' : '#f9f9f9', marginLeft: '15px', width: '50px',  color: '#000'}}>2019</C.Button>         
            </C.StateArea>
                }
            </G.Body>
            {loadingModal &&  
            <C.ModalContainer>
              <C.Logo src={"/loading.svg"} />
            </C.ModalContainer>
            }
    {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center'}}>
           <M.ModalHeader style={{ height:'0px'}}>
            {actsFull &&
              <G.PopUpArea  style={{marginLeft: '0px', marginTop: '300px', width: '700px'}}>
                  <G.PopUpCloseArea onClick={() => setActsFull('')}><CloseIcon style={{fontSize: '20px'}}/></G.PopUpCloseArea>
                  <G.DistacInfoText style={{ backgroundColor: '#e5e5e5', padding: '7px'}}>
                                  <TaskAltOutlinedIcon style={{fontSize: '24px',  marginRight: '5px' }}/>
                                    Atividades
                  </G.DistacInfoText>
                    {atividades.map((item , index) => (
                                <G.TableArea key={index} style={{justifyContent: 'space-around'}}>
                                <G.FieldArea size={'30%'}><G.InfoText>{item.nome_atividade}</G.InfoText></G.FieldArea>
                                <G.FieldArea style={{backgroundColor: '#f5f5f5', justifyContent:'center'}} size={'20%'}><G.InfoText >{item.nome}</G.InfoText></G.FieldArea>
                                <G.FieldArea style={{color: '#fff', backgroundColor: '#2E8B57', justifyContent:'center'}} size={'10%'}><G.InfoText>{new Date(item.inicio).toLocaleDateString('pt-BR')}</G.InfoText></G.FieldArea>
                                <G.FieldArea style={{color: '#fff',backgroundColor: '#E95839', justifyContent:'center'}} size={'10%'}><G.InfoText>{new Date(item.fim).toLocaleDateString('pt-BR')}</G.InfoText></G.FieldArea>
                                <G.FieldArea size={'10%'} style={{color: '#fff',backgroundColor: '#333', justifyContent:'center'}}><G.InfoText>{
                                                                    parseInt(moment.duration(item.duracao).asHours()) + 'h' + 
                                                                    parseInt(moment.duration(item.duracao).asMinutes() % 60) + 'm'}</G.InfoText></G.FieldArea>
                                </G.TableArea>
                    ))}
    
                                      <G.InfoArea style={{backgroundColor: '#f5f5f5', padding: '7px', width: '170px'}}>
                                        <G.InfoText style={{marginRight: '3px'}}>Total da fase:  </G.InfoText>
                                        <G.DistacInfoText style={{marginRight: '10px'}}>
                                        {parseInt(moment.duration(atividades.reduce((a, b) => a + parseFloat(b.duracao), 0)).asHours()) + 'h' 
                                        + parseInt(moment.duration(atividades.reduce((a, b) => a + parseFloat(b.duracao), 0)).asMinutes() % 60) + 'm'} 
                                        </G.DistacInfoText> 
                                      </G.InfoArea>
              </G.PopUpArea>
            }
            {addFase &&
              <G.PopUpArea  style={{marginLeft: '0px', marginTop: '400px', width: '700px'}}>
                  <G.PopUpCloseArea onClick={() => setAddFase('')}><CloseIcon style={{fontSize: '20px'}}/></G.PopUpCloseArea>
                  <G.DistacInfoText style={{ backgroundColor: '#e5e5e5', padding: '7px'}}>
                    <TaskAltOutlinedIcon style={{fontSize: '24px',  marginRight: '5px' }}/>
                    Adicionar Fase
                  </G.DistacInfoText>
                  <G.BoxBody style={{color: '#fff', flexDirection: 'row', justifyContent: 'start', minHeight: '250px', alignItems: 'start'}}>
                    <G.FormInputArea  style={{width: '50%'}}>
                      <G.FormInputLabel>Fase</G.FormInputLabel>
                      <G.FormSelect   onChange={e => GetAtvs(e.target.value)} onMouseOver={() => GetSelect(4)}>
                        <option>Selecione a Fase a adicionar</option>
                        {phaseType.map(p => (
                          <option key={p.codfase} value={p.codfase}>{p.fase}</option>
                        ))}
                      </G.FormSelect>
                    </G.FormInputArea>  
                    {atvs !== '' &&
                      <G.InfoArea style={{flexDirection: 'column', alignItems: 'start'}} >  
                      <G.InfoArea style={{fontSize: '12px', color: '#000', borderBottom: '1px solid #ccc', padding: '10px', borderRadius: '0px', width: '250px', marginBottom: '10px'}}>Atividades</G.InfoArea>
                      {atvs.map((item, key)=>(
                                    <G.FormInputArea key={key} style={{}}>
                                    <G.FormInputLabel style={{backgroundColor: '#F9FDFC', width: '100%'}}>{item.nome_atividade}</G.FormInputLabel>
                                  </G.FormInputArea>
                            ))}  
                      </G.InfoArea>
                    }  
                  </G.BoxBody>
              <G.InfoArea>
                    <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => AddFase()}>Adicionar</G.Button>
                </G.InfoArea> 
              </G.PopUpArea>
            }
            </M.ModalHeader>  
           <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'center', marginTop: '5px'}}>
              <G.BoxBody style={{ margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>
                <G.FormInputArea style={{width: '15%'}}>
                  <G.FormInputLabel>Código</G.FormInputLabel> <G.FormInput defaultValue={filteredList[selectedProcess].codproc} {...register("codproc")}/>                      
                </G.FormInputArea>
                <G.FormInputArea  style={{width: '31%'}}> 
                  <G.FormInputLabel style={{ borderLeft: '3px solid blue'}}>Identificação</G.FormInputLabel><G.FormInput defaultValue={filteredList[selectedProcess].identificacao} {...register("identificacao")}/>
                </G.FormInputArea>
                <G.FormInputArea style={{width: '20%'}}>
                  <G.FormInputLabel>Processo</G.FormInputLabel><G.FormInput defaultValue={filteredList[selectedProcess].processo} {...register("processo")}/>                      
                </G.FormInputArea>
                <G.FormInputArea style={{width: '27%'}}>
                  <G.FormInputLabel>Junta</G.FormInputLabel><G.FormInput defaultValue={filteredList[selectedProcess].junta} {...register("junta")}/>                
                </G.FormInputArea>
                <G.FormInputArea  style={{width: '20%'}}>
                  <G.FormInputLabel>Entrada</G.FormInputLabel>

                  <G.FormInput  type="date" defaultValue={moment(filteredList[selectedProcess].entrada).format("YYYY-MM-DD")} {...register("entrada", {setValueAs: v => moment(v).format("YYYY-MM-DD") })}/>
                </G.FormInputArea>      
                <G.FormInputArea style={{width: '20%'}}>
                  <G.FormInputLabel>Prazo: </G.FormInputLabel><G.FormInput defaultValue={moment(filteredList[selectedProcess].prazo).format("YYYY-MM-DD")} type="date"
                  {...register("prazo", {setValueAs: v => moment(v).format("YYYY-MM-DD") })}/>                      
                </G.FormInputArea>
                <G.FormInputArea style={{width: '20%'}}>
                </G.FormInputArea>
                <G.FormInputArea style={{width: '48%'}}>
                  <G.FormInputLabel style={{ borderLeft: '3px solid green'}}>Reclamante</G.FormInputLabel><G.FormInput defaultValue={filteredList[selectedProcess].reclamante} {...register("reclamante")}/>                      
                </G.FormInputArea>
                <G.FormInputArea style={{width: '48%',}}>
                  <G.FormInputLabel style={{ borderLeft: '3px solid orange'}}>Reclamada</G.FormInputLabel><G.FormInput defaultValue={filteredList[selectedProcess].reclamada} {...register("reclamada")}/>                      
                </G.FormInputArea>
                {(filteredList[selectedProcess].tipo === 2 || filteredList[selectedProcess].tipo === 3 ) &&
                <G.FormInputArea style={{width: '48%'}}>
                <G.FormInputLabel>Advogado</G.FormInputLabel>
                <G.FormSelect {...register("codadv")} defaultValue={filteredList[selectedProcess].codadv}>
                <option >{filteredList[selectedProcess].nomeadv}</option>
                  {lawyers.map(a => (
                    <option key={a.codadv}  value={a.codadv}>{a.nomeadv}</option>
                  ))}
                </G.FormSelect>                      
                </G.FormInputArea>
                }
                {filteredList[selectedProcess].tipo === 3 &&
                <G.FormInputArea  style={{width: '48%'}}>
                  <G.FormInputLabel>Empresa</G.FormInputLabel>
                  <G.FormSelect {...register("cod_empresa")} defaultValue={filteredList[selectedProcess].cod_empresa}>
                  <option >{filteredList[selectedProcess].empresa}</option>
                  {company.map(a => (
                  <option key={a.cod_empresa}  value={a.cod_empresa}>{a.empresa}</option>
                  ))}
                  </G.FormSelect> 
                </G.FormInputArea>
                }          
                <G.FormInputArea style={{width: '48%',}}>
                  <G.FormInputLabel>Calculista</G.FormInputLabel>
                     <G.FormSelect {...register("calculista")} defaultValue={filteredList[selectedProcess].calculista}>
                     <option >{filteredList[selectedProcess].id_usuario}</option>
                        {users.map(u => (
                          <option key={u.id_usuario} value={u.nome}>{u.nome}</option>
                        ))}
                      </G.FormSelect>                     
                </G.FormInputArea>
                {filteredList[selectedProcess].tipo === 1 &&
                <>
                <G.FormInputArea style={{width: '48%'}}>
                  <G.FormInputLabel>Perito Responsável</G.FormInputLabel><G.FormInput defaultValue={filteredList[selectedProcess].perito} {...register("nomeadv")}/>                      
                </G.FormInputArea>
                <G.FormInputArea style={{width: '15%'}}>
                  <G.FormInputLabel>Nota Fiscal</G.FormInputLabel><G.FormInput defaultValue={filteredList[selectedProcess].nota_fiscal} {...register("nota_fiscal")}/>                      
                </G.FormInputArea>
                <G.FormInputArea style={{width: '23%'}}>
                <G.FormInputLabel>Honorário</G.FormInputLabel><G.FormInput 
                   defaultValue={parseFloat(filteredList[selectedProcess].honorario).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} {...register("honorario" , {
                    setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")),
                  })}/>                        
                </G.FormInputArea>
                <G.FormInputArea style={{width: '23%'}}>
                  <G.FormInputLabel>Homologado</G.FormInputLabel><G.FormInput 
                  defaultValue={parseFloat(filteredList[selectedProcess].homologado).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} {...register("homologado" , {
                    setValueAs: v => Number(v.replace('.', "").replace(',', '.').replace(/[^0-9.-]+/g,"")),
                  })}/>                      
                </G.FormInputArea>
                </>
                }
              </G.BoxBody>
        
              <G.Box style={{ flexDirection: 'column', height: '180px', width: filteredList[selectedProcess].tipo === 3 ? '95%' : '70%', backgroundColor: '#ddd', justifyContent: 'start'}}>
                
                <G.TableHeader style={{width: '100%', borderBottom: '2px solid #bbb', backgroundColor: '#ddd'}}>
                  <G.OptionsArea style={{width: '100%', justifyContent: 'space-between'}}>
                    <G.FieldArea style={{marginLeft: '15px'}} size={'25%'}><G.DistacInfoText>Fase</G.DistacInfoText></G.FieldArea>
                    <G.FieldArea size={'10%'}><G.DistacInfoText>Data</G.DistacInfoText></G.FieldArea>
                    <G.FieldArea size={'15%'}><G.DistacInfoText>Estado</G.DistacInfoText></G.FieldArea>

                    {filteredList[selectedProcess].tipo === 3 &&
                    <>
                    <G.FieldArea size={'10%'}><G.DistacInfoText>Cobrança</G.DistacInfoText></G.FieldArea>
                    <G.FieldArea size={'10%'}><G.DistacInfoText>Estimado</G.DistacInfoText></G.FieldArea>
                    <G.FieldArea size={'10%'}><G.DistacInfoText>Faturado</G.DistacInfoText></G.FieldArea>
                    </>}
                    <G.FieldArea size={'15%'}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                  </G.OptionsArea>
                </G.TableHeader>
                <G.TableBodyScroll style={{}}>
                  {fasesList.map((item, key) => (
                    <G.TableArea key={key} style={{justifyContent: 'space-between'}}>
                      <G.FieldArea style={{marginLeft: '15px', borderLeft: '5px solid green',  borderRadius: '3px'}} size={'25%'}><G.InfoText style={{marginLeft: '10px'}} >{item.fase}</G.InfoText></G.FieldArea>
                      <G.FieldArea size={'10%'}><G.InfoText>{new Date(item.datafase).toLocaleDateString('pt-BR')}</G.InfoText></G.FieldArea>
                      <G.FieldArea size={'15%'} style={{ borderBottom: item.estado === 1 ? '3px solid green' : '3px solid orange', borderRadius: '0px', justifyContent: 'center'}}><G.InfoText>{item.tp_est_descricao}</G.InfoText></G.FieldArea>
                      {filteredList[selectedProcess].tipo === 3 &&
                      <>
                      <G.FieldArea size={'10%'} style={{justifyContent: 'center' }}><G.InfoText>{item.cobranca}</G.InfoText></G.FieldArea>
                      <G.FieldArea size={'10%'}><G.InfoText>{parseFloat(item.valorcobrado).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</G.InfoText></G.FieldArea>
                      <G.FieldArea size={'10%'} style={{justifyContent: 'center' }}><G.InfoText>{item.pago}</G.InfoText></G.FieldArea>
                      </>}
                      <G.FieldArea size={'15%'}><G.InfoText> 
                                                              <G.IconButton style={{backgroundColor: '#E95839' }}> <DeleteIcon fontSize="small"/> </G.IconButton>
                                                              <G.IconButton style={{backgroundColor: '#FFB400' }} onClick={() => GetAtividades(item.idfase) }><AutoAwesomeMotionIcon fontSize="small"/> </G.IconButton>
                      </G.InfoText></G.FieldArea>

                    </G.TableArea> 
                  ))}
                </G.TableBodyScroll>
              </G.Box>
            </M.ModalBody>
            <M.ModalFooter style={{ flexDirection: 'row', height: '20px' }}>
            {fasesList.filter(qitem => qitem.estado === 1).length === 0 &&
               <G.Button style={{backgroundColor: '#1E90FF'}} onClick={() => setAddFase(true)}>Adicionar Fase</G.Button>
              }
              <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick()}>Fechar</G.Button>
              <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Salvar</G.Button>
            </M.ModalFooter>
          </M.ModalContentLarge>
        </M.ModalContainer>
      }
        </C.Container>

    );
}
