import React from 'react';
import * as S from './styled'

import { VerticalBar } from '../VerticalBar/VerticalBar';

export const PageHeader = ({ icon, title, width, minHeight, backgroundColor, color, fontSize, children, subTitle  }) => {

	return (
		<S.HeaderContainer style={{width: width, minHeight: minHeight, backgroundColor: backgroundColor }}>
			<S.HeaderArea>
					{icon}
					<VerticalBar/>
					<S.HeaderTitle style={{color: color, fontSize: fontSize}} color={color}>
					{title}
					<div style={{fontSize: '0.8em'}}>{subTitle}</div>
					</S.HeaderTitle>
			</S.HeaderArea>
			<div style={{flexDirection: 'row', display: 'flex'}}>
				{children}
			</div>
		</S.HeaderContainer>
	);

}
