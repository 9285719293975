import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from 'moment'

import * as C from './styled'
import * as G from '../../../styles/global'
import * as M from '../../../styles/modal'

import Api from '../../../services/api'
import FinancialApi from '../../../services/financialApi'
import { UserContext } from '../../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import TaskIcon from '@mui/icons-material/Task';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export const BankAccounts = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState(true)
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState()
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)

  const [bank, setBank] = useState([])
  const [bankAccountType, setBankAccountType] = useState([])
  const [selectedBankAccountType, SetSelectedBankAccountType] = useState('')

  const [accountType, setAccountType] = useState('')
  const [isValid, setIsValid] = useState('')
  const [managerField, setManagerField] = useState(false)
  



  /// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')


  
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
        return data.name.toLowerCase().search(value) !== -1 // cnt_descricao
            || data.bank_name.toLowerCase().search(value) !== -1 // bco_nome_banco
            || data.branch.toLowerCase().search(value) !== -1 // branch
            || data.code.toLowerCase().search(value) !== -1 // code
            || data.bank_account_type_name.toLowerCase().search(value) !== -1 // tp_cnt_descricao
        })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }
  


  /// FUNCTIONS ////////////////////////////////////////////////////////////////

  
  const GetBankAccountt = async () => {
    setLoading(true)
    let res = await FinancialApi.getBankAccount();
    setList(res)
    setFilteredList(res)
    setLoading(false)
  }
  

  const GetSelect =  async (value) => {
    switch (value) {
      case 1:
        let res1 = await FinancialApi.getBank()
        setBank(res1)
      break;

      case 2:
        let res2 = await FinancialApi.getBankAccountType()
        setBankAccountType(res2)
      break;

      default:
      break;
    }
  }
  

  const AddClick = () => {
    setModalType(1)
    setModal(true)
  }  

  const EditClick = (key) => {
    setSelectedKey([key])
    setModalType(2)
    setModal(true)
  }

  const DelClick = async (key) => {
    setSelectedKey([key])
    setModalType(3)
    setModal(true)
  }

  const ActClick = (key) => {
    setSelectedKey([key])
    setModalType(4)
    setModal(true)
  }


  const onSubmit = async (data) => {
    //console.log("onSubmit > onSubmit: ", data)
    switch (modalType) {
      case 1:
        const userAdd = ({
          created_by: user.id,
          changed_by: user.id
        })
        const add = ({...data, ...userAdd})
        let res1 = await FinancialApi.postBankAccount(add)
        // console.log("addAccount > res1 : ", res1)
      break;

      case 2:
        const userUpd = ({
          changed_by: user.id
        })
        const upd = ({...data, ...userUpd})
        let res2 = await FinancialApi.postBankAccount(upd)
        //console.log("upgAccount > res2 : ", res2)
      break;

      default:
      break;
    }
    CloseModalClick2()
  };


  const DelBankAccountt = async (id) => {
    let res = await FinancialApi.delBankAccount(id);
    CloseModalClick2()
  }


  const ActBankAccountt = async (id, estado) => {
    const data = ({
      id: id, 
      state: estado,
      changed_by: user.id
    })
    let res = await FinancialApi.postBankAccount(data)
    //console.log("ActBankAccountt > res : ", res)
    GetBankAccountt()
  }


  const CloseModalClick = () => {
    setLoading(true)
    setSelectedKey('')
    setBank('')
    setBankAccountType('')
    setAlertError('')
    GetBankAccountt()
    setModal(false)
    setModalType('')
    reset()
    setLoading(false)
  }


  const CloseModalClick2 = () => {
    setLoading(true)
    setSelectedKey('')
    // setBank('')
    // setBankAccountType('')
    setAlertError('')
    GetBankAccountt()
    setModal(false)
    setModalType('')
    reset()
    setLoading(false)
  }


  /// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    GetBankAccountt();
  }, [state]);



/// CONSOLE LOG ////////////////////////////////////////////////////////////////





  return (
    <G.Container>

      <G.Header>

        <G.HeaderTitleArea style={{ width: '264px' }}>
          <AccountBalanceWalletIcon style={{ fontSize: '32px', margin: '0px 0px 0px 0px' }} />
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <G.MainTitle style={{ fontSize: 'var(--font-size-title)' }}>Contas Bancárias</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>

        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
          <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
            <G.SearchIconArea>
              <SearchIcon />
            </G.SearchIconArea>
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SearchArea>        
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>

      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>
              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px' }}>
                  <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.DistacInfoText></G.DistacInfoText></G.FieldArea>
                  <G.FieldArea size={'230px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('name')}><G.DistacInfoText>Nome da Conta</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'name') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'210px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('bank_name')}><G.DistacInfoText>Banco</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'bank_name') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'100px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('branch')}><G.DistacInfoText>Agência</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'branch') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'110px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('code')}><G.DistacInfoText>Conta</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'code') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'120px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('bank_account_type_name')}><G.DistacInfoText>Tipo Conta</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'bank_account_type_name') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              {(loading === true && filteredList.length === 0 ) &&  
                <G.Loading2 src={"/loading.svg"} />
              }

              <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                {filteredList.length === 0 && 
                  <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                    *** Não existem itens a exibir. ***
                  </G.Text>
                }
                {filteredList.length !== 0 &&
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key}>
                        {/* <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border:  item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839' }}> */}
                        <G.FieldArea size={'48px'} style={{ padding: '0px', justifyContent: 'center' }}><G.Text><C.ContactTypeIconArea style={{ border: 'var(--circular-border)' }}>
                          <AccountBalanceWalletIcon style={{ fontSize: '22px', color: 'var(--icon-color)' }} /></C.ContactTypeIconArea></G.Text>
                        </G.FieldArea>
                        <G.FieldArea size={'230px'}><G.Text>{item.name}</G.Text></G.FieldArea>
                        <G.FieldArea size={'210px'}><G.Text>{item.bank_name}</G.Text></G.FieldArea>
                        <G.FieldArea size={'100px'}><G.Text>{item.branch}</G.Text></G.FieldArea>
                        <G.FieldArea size={'110px'}><G.Text>{item.code}</G.Text></G.FieldArea>
                        <G.FieldArea size={'120px'}><G.Text>{item.bank_account_type_name}</G.Text></G.FieldArea>
                        <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                          <G.IconButton onClick={() => EditClick(key)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}><EditIcon fontSize="small" /></G.IconButton>
                          {/* <G.IconButton onClick={() => ActBankAccountts(item.id, item.state === 0 ? 1 : 0)} style={{ backgroundColor: item.state === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.state === 0 ? <CheckIcon fontSize="small"/> : <BlockIcon fontSize="small"/>}</G.IconButton> */}
                          {/* <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.state === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>{item.state === 0 ? <CheckIcon fontSize="small"/> : item.state === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}</G.IconButton> */}
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}><DeleteIcon fontSize="small"/> </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                }
              </G.TableBody>
            </G.Box>
          </>
        }

      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentSmall>

            <M.ModalHeader style={{ backgroundColor: modalType === 1 ? '#BDF5D6' : modalType === 2 ? '#C8E4FF' : modalType === 3 ? '#FCAC9B' : (filteredList[selectedKey].state === 0 ? '#BDF5D6' : '#ccc'), justifyContent: 'space-between', bordeRadius: '15px' }}>
              <C.ModalTitle>{modalType === 1 ? 'Adicionar Conta' : modalType === 2 ? 'Editar Conta' : modalType === 3 ? 'Excluir Conta' : (filteredList[selectedKey].state === 0 ? 'Ativar Conta' : 'Desativar Conta')}</C.ModalTitle>
                <G.InfoArea>
                  <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick2} />
                </G.InfoArea>
            </M.ModalHeader>

            <M.ModalBody style={{ flexDirection: 'column'}}>

              {(modalType === 2 || modalType === 3) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].id} {...register("id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBodyScroll style={{ flexDirection: 'column' }}>
                  <G.FormInputArea>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #AAA' }}>
                      Nome da Conta
                    </G.FormInputLabel>

                      <G.FormInput autoFocus defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].name} {...register("name", {required: true})} />
                      {/* {errors.name && <span style={{ fontSize: '12px', color: 'red', }}>(*) Preencha o Nome da Conta.</span>} */}

                  </G.FormInputArea>
                  <G.FormInputArea>
                    <G.FormInputLabel style={{ borderLeft: modalType === 1 ? '3px solid #2E8B57' : modalType === 2 ? '3px solid #1E90FF' : '3px solid #AAA' }}>
                      Tipo
                    </G.FormInputLabel>
                    <G.FormSelect {...register("bank_account_type_id", { setValueAs: v => v === '' ? null : v, required: true })}  onMouseDown={() => GetSelect(2)}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].bank_account_type_id}>{modalType === 1 ? 'Selecione o Tipo da Conta' : filteredList[selectedKey].bank_account_type_name}</option>
                      {bankAccountType.map(i => (
                        <option key={i.id} value={i.id}>{i.name}</option>
                      ))}
                    </G.FormSelect>
                    {/* {errors.bank_account_type_id && <span style={{ fontSize: '12px', color: 'red', }}>(*) Selecione o Tipo da Conta.</span>} */}
                  </G.FormInputArea>
                  {
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', margin: '6px 0px' }}>
                      <G.FormInputArea style={{ width: '100%' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                          Banco
                        </G.FormInputLabel>
                        <G.FormSelect {...register("bank_id", {required: true}, { required: accountType !== 1 ? true : false })} onMouseDown={() => GetSelect(1)}>
                          <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].bank_id}>{modalType === 1 ? 'Selecione o Banco' : filteredList[selectedKey].bank_name}</option>
                          {bank.map(i => (
                            <option key={i.id} value={i.id}>{i.name}</option>
                          ))}
                        </G.FormSelect>
                        {/* {errors.bank_id && <span style={{ fontSize: '12px', color: 'red', }}>(*) Selecione o Banco.</span>} */}
                      </G.FormInputArea>
                      <G.FormInputArea style={{ width: '30%' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                          Agência
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].branch} {...register("branch", {required: true})} />
                      </G.FormInputArea>
                      <G.FormInputArea style={{ width: '64%' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                          Conta
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].code} {...register("code", {required: true})} />
                      </G.FormInputArea>
                    </div>
                  }
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', margin: '0px' }}>
                    <G.FormInputArea style={{ width: '54%' }}>
                      <G.FormInputLabel style={{ borderLeft: '3px solid #AAA' }}>
                        Saldo Inicial
                      </G.FormInputLabel>
                      <G.FormInput defaultValue={modalType === 1 ? '' : parseFloat(filteredList[selectedKey].initial_balance_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})} {...register("initial_balance_value" , {
                                  setValueAs: v => Number(v.replace('.', '').replace(',', '.').replace(/[^0-9.-]+/g,''))})} />
                    </G.FormInputArea>
                    
                    <G.FormInputArea style={{ width: '40%' }}>
                      <G.FormInputLabel style={{ borderLeft: '3px solid #AAA' }}>
                        Data Inicial
                      </G.FormInputLabel>
                      <G.FormInput type="date" defaultValue={modalType === 1 ? '' :  moment(filteredList[selectedKey].initial_balance_date).format('YYYY-MM-DD')} {...register("initial_balance_date")} />
                    </G.FormInputArea>
                  </div>


                  {/* <G.BreakLine style={{ height: '12px' }}/> */}

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '16px 0px 16px -5px' }}>
                    {(Object.keys(errors).length > 0) &&  
                      <div role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '92%', padding: '7px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                        <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}</div>
                        <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                      </div>
                    }
                  </div>

                  <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '0px', marginBottom: '10px' }}>
                    <G.FormInputLabel style={{ width: '450px', justifyContent: 'flex-start', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                      Observação
                    </G.FormInputLabel>
                    <G.TextArea {...register("note", { setValueAs: v => v === '' ? null : v })} 
                          style={{ width: '430px', height: '40px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                    >
                      {modalType === 1 ? '' :  filteredList[selectedKey].note}
                    </G.TextArea>
                  </G.FieldArea>

                  <G.BreakLine style={{ height: '12px' }}/>

                </G.BoxBodyScroll>
              }
            </M.ModalBody>
            
            

            {(modalType === 3) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja excluir a Conta:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].name}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            {(modalType === 4) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja {filteredList[selectedKey].state === 0 ? 'ativar' : 'desativar'} a Conta:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].name}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2) &&
                // <G.Button onClick={SendAccounts} style={{backgroundColor: '#333', width: '120px'}}>Enviar</G.Button>
                // <G.Button style={{backgroundColor: '#333'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelBankAccountt(filteredList[selectedKey].id)}>Sim</G.Button>
                </>
              }
              {modalType === 4 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActBankAccountt(filteredList[selectedKey].id, filteredList[selectedKey].state === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                </>
              }
            </M.ModalFooter>

          </M.ModalContentSmall>
        </M.ModalContainer>
      }
    </G.Container>
  );
}
