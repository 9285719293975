import styled from 'styled-components';


/// GENERAL ////////////////////////////////////////////////////////////////////

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1250px;
  min-height: 60px;
  padding: 0px 16px;
  margin: 20px 5px 5px 5px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #ccc;
  background-color: #f9f9f9;
`;

export const HeaderTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 40px;
  padding: 5px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  padding: 8px 10px;
  margin: 20px 6px 20px 6px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background-color: #FFF;
`;

export const PainelBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 8px;
`;

export const BoxScroll = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 960px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 20px 5px 20px 5px;
  padding: 0px 15px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 22rem;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;  
    background-color: #777;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 5px 5px 0px 0px;
  padding: 10px 10px;
`;

export const BoxHeaderTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
`;

export const BoxHeaderOptionsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 5px;
`;

export const BoxBody = styled.div`
  display: flex;
  margin: 10px 0px;
  padding: 10px;
`;

export const BoxBodyScroll = styled.div`
  width: 100%;
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
  // scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #e0e0e0;

  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;  
    background-color: #999;
  }
`;

export const BoxFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #e0e0e0;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 20px 10px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1000px;
  min-height: 64px;
  padding: 16px;  
  margin: 24px 8px 24px 8px;
  background-color: inherit;
`;

export const OptionsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 4px;
`;

export const InfoArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 4px 12px;
`;

export const Loading = styled.img`
  width: auto;
  margin-top: 100px;
`;

export const Loading2 = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  margin: 60px;
`;

export const Image = styled.img`
  width: auto;
`;

export const ActionButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 124px;
  min-height: 32px;
  padding: 4px;
  border-radius: 4px;
`;

export const UserTypeArea = styled.div`
display: flex;
width: auto;
height: auto;
justify-content: space-around;
align-items: center;
padding: 2px;
border-radius: 4px;
background-color: inherit;
color: #000;
//font-size: 15px;
`;

export const UserTypeIcon = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 30px;
height: 15px;
padding: 3px;
border: 1px solid #CECECE;
border-radius: 4px;
background-color: #555;
color: #fff;
//font-size: 10px;
font-weight: bold;
`;

export const ActsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #F7F7F7;
`;

export const ActsName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #DCDCDC;
  background-color: #F7F7F7;
`;


/// CARDS //////////////////////////////////////////////////////////////////////

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  min-height: 200px;
  padding: 2px;
  border-radius: 6px;
  margin: 10px;
  box-shadow: 0px 0px 2px 1px #ccc;
  // background-color: #fff;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  padding: 10px 10px;
  border-bottom: 1px solid #DFDFDF;
  border-radius: 6px 6px 0px 0px;
  margin-bottom: 2px;
  background-color: #f7f7f7
`;

export const CardHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
`;

export const CardHeaderTitle = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  //font-size: 15px;
  color: var(--text-color);
`;

export const CardHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 500px;
  padding: 0px 5px;
`;

export const CardHeaderNumber = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  //font-size: 13px;
  font-weight: bold;
  color: var(--text-color);
`;

export const CardBody = styled.div`
  display: flex;
  padding: 8px;
`;

export const CardBodyScroll = styled.div`
  display: flex;
  padding: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #f9f9f9;
  }
`;

export const CardBodyA = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 6px;
  margin: 0px 0px;
`;

export const CardBodyB = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 200px;
  padding: 2px;
  border-radius: 6px;
  border-left: 1px solid #DDD;
`;

export const CardBodyText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 244px;
  min-height: 16px;
  padding: 8px;
  margin: 4px;
  border-radius: 4px;
  background-color: #f5f5f5;
  //font-size: 12px;
`;

export const CardBodyStatusText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 3px;
  border-radius: 6px;
  //font-size: 12px;
`;

export const CardFooter = styled.div`
  display: flex;
  flex: 1; 
  justify-content: center;
  align-items: center;
  min-height: 30px;
  border-radius: 0px 0px 6px 6px;
  border-top: 1px solid #DFDFDF;
  background-color: #f2f2f2;
`;



/// DATA TABLES ////////////////////////////////////////////////////////////////

export const TableArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 0px;
  border-bottom: 1px solid #e0e0e0;
  /* cursor: pointer; */
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const TableAreaHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  min-height: 30px;
  min-width: ${(props) => props.size};
  max-width: ${(props) => props.size};
  padding: 0px 5px;
  border-radius: 0px;
  &:hover {
    border-bottom: 1px dotted #333;
    transition: 0.5s ease-in-out;
  }
`;

export const FieldArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size};
  /* min-width: ${(props) => props.size}; */
  /* max-width: ${(props) => props.size}; */
  min-height: 32px;
  padding: 0px 5px;
  border-radius: 8px;
`;

// export const TableHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   width: 100%;
//   height: 30px;
//   padding: 10px 10px;
//   border-bottom: 1px solid #e0e0e0;
//   border-radius: 5px 5px 0px 0px;
//   background-color: #fff;
// `;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: 2px;
  margin: 0px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f5f5f5;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 10px 0px 10px 0px;
  padding: 10px 10px;
`;

export const TableBodyScroll = styled.div`
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 5px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 320px;
  // scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #999;
  }
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 20px;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
`;



// -----------------------------------------------------------------------------

export const TableHeaderFieldArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size};
  height: 40px;
  padding: 0px 1px;
  margin: 0px 1px;
  border-radius: 4px;
  /* background-color: orange; */
  cursor: ${props => props.cursorHover === false ? 'default' : 'pointer'};
  &:hover {
    background-color: ${props => props.cursorHover === false ? 'undefined' : '#eee'};
  }
`;

export const TableHeaderFieldTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 36px;
  padding: 0px 2px;
  border-radius: 4px 0px 0px 4px;
  //font-size: 14px;
  font-weight: bold;
  /* background-color: #ccc; */
`;

export const TableHeaderFieldIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0px 2px;
  border-radius: 0px 4px 4px 0px;
  //font-size: 14px;
  /* background-color: #ccc; */
`;

export const TableBodyFieldArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 1px;
  margin: 0px 1px;
  border-radius: 4px;
  /* background-color: orange; */
`;

export const TableBodyFieldText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: ${(props) => props.size};
  min-height: 40px;
  padding: 2px 0px;
  margin: 0px 0px;
  border-radius: 4px;
  /* background-color: lightgray; */
  //font-size: 12px;
`;

export const TableInfoText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 20px 0px;
  //font-size: 13px;
`;



// -----------------------------------------------------------------------------



/// TEXTS //////////////////////////////////////////////////////////////////////

export const MainTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  //font-size: 22px;
  font-weight: bold;
`;

export const SubTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  //font-size: 18px;
  font-weight: bold;
`;

export const SmallTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  //font-size: 15px;
  font-weight: bold;
`;

export const DistacInfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //font-size: 14px;
  font-weight: bold;
`;

export const InfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  //font-size: 11px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  //font-size: 12px;
`;

export const TextViewScroll = styled.div`
  padding: 15px;
  //font-size: 12px;
  border-radius: 10px;
  color: #000;
  overflow-y: scroll;
  overflow-x: hidden;
  // scrollbar-width: thin;
  &::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #333;
  } 
`;




/// BUTTONS ////////////////////////////////////////////////////////////////////

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 20px;
  padding: 6px 8px;
  margin: 0px 5px;
  border-radius: 6px;
  box-shadow: 0px 2px 2px 1px #ccc;
  color: #fff;
  cursor: pointer;
  transition: transform 0.25s ease;
  &:hover {
    filter: brightness(1.2);
  }
`;

export const ButtonOutLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 20px;
  padding: 6px;
  color: #fff;
  border-radius: 7px;
  //font-size: 12px;
  cursor: pointer;
  margin: 0px 5px;
  &:hover {
    filter: brightness(1.1);
    font-weight: bold;
  }
`;

export const ButtonUnderLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 160px;
  height: 20px;
  color: #333;
  border-bottom: 2px dotted #333;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #333;
  }
`;

export const SpecialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  width: 160px;
  height: 25px;
  border-bottom: 2px solid #2E8B57;
  color: #000;
  cursor: pointer;
  &:hover {
    border-bottom: 2px solid #333;
  }
`;

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 20px;
  padding: 6px;
  margin: 0px 1px;
  color: #fff;
  //font-size: 12px;
  border-radius: 6px;
  cursor: pointer;
  box-shadow: 0px 3px 3px #ccc;
  transition: transform 0.25s ease;
  &:hover {
    filter: contrast(140%);
    /* filter: brightness(1.1); */
    transition: 0.25s ease-in-out;
    font-weight: 480;
  }
  &:active {
    transform: scale(0.96);
  }
`;

export const DownloadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 20px;
  padding: 6px;
  margin: 0px 5px;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0px 2px 2px #ccc;
  //font-size: 13px;
  cursor: pointer;
  transition: transform 0.25s ease;
  &:hover {
    filter: contrast(140%);
    /* filter: brightness(1.1); */
    transition: 0.25s ease-in-out;
    font-weight: 480;
  }
  &:active {
    transform: scale(0.96);
  }
`;

export const CircleButton = styled.div`
  padding: 5px;
  width: 20px;
  height: 20px;
  color: #000;
  //font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  background-color: #f7f7f7;
  &:hover {
    background-color: #EFFFF1;
  }
`;

export const BigButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  padding: 7px;
  width: 160px;
  height: 180px;
  border-radius: 10px;
  border: 1px solid #ffb400;
  cursor: pointer;
  margin: 30px;
  &:hover {
    background-color: #ffb400;
  }
`;

export const PlayIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: 40px;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
  background-color: #F0F0F0;
  color: #444;
  cursor: pointer;
  transition: transform 0.2s ease;
  &:hover {
    transition: 0.25s ease-in-out;
    background-color: var(--primary-color);
    color: #F0F0F0;
  }
  &:active {
    transform: scale(0.94);
  }
`;

export const FakeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 20px;
  padding: 6px;
  margin: 0px 5px;
  color: #fff;
  border-radius: 7px;
  box-shadow: 0px 2px 2px #ccc;
  //font-size: 13px;
`;
 



/// OTHERS /////////////////////////////////////////////////////////////////////

export const StateArea = styled.div`
background-color: #f1f1f1;
height: 40px;
border-radius: 10px;
padding: 0px 15px;
display: flex;
align-items: center;
width: 130px;
justify-content: space-around;
`;


export const SwitchArea = styled.div`
background-color: #f1f1f1;
height: 40px;
border-radius: 10px;
padding: 3px 7px;
display: flex;
align-items: center;
justify-content: space-around;
gap: 10px;
`;

export const NOptionsArea = styled.div`
background-color: #f1f1f1;
height: 40px;
border-radius: 10px;
padding: 3px 7px;
display: flex;
align-items: center;
justify-content: space-around;
gap: 10px;
`;

export const NInfoArea = styled.div`
background-color: #f1f1f1;
height: 40px;
border-radius: 10px;
padding: 3px 7px;
display: flex;
align-items: center;
justify-content: space-around;
gap: 10px;
`;

export const SearchArea = styled.div`
color: #777;
height: 50px;
padding: 5px;
display: flex;
align-items: center;
justify-content: center;

`;

export const SearchIconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 30px;
  padding: 5px;
  border: 1px solid #E5E5E5;
  border-radius: 6px 0px 0px 6px;
  border-right: none;
  /* background-color: #E8E8E8; */
  background-color: #F0F0F0;
`;

export const SearchInput = styled.input`
width: 200px;
font-size: 14px;
border: none;
outline: none;
background-color: #f1f1f1;
height: 35px;
border-radius: 15px;
font-family: inherit;
`;

export const SearchInput2 = styled.input`
  width: 88%;
  height: 90%;
  padding: 0px 8px;
  border: none;
  border-radius: 0px 6px 6px 0px;
  outline: none;
  background-color: inherit;
  color: #444;
  font-family: inherit;
  //font-size: 12px;
`;


export const Input = styled.input`
  border: none;
  padding: 5px;
  border-radius: 6px;
  outline: none;
  background-color: #f1f1f1;
  font-family: inherit;
`;

export const Select = styled.select`
  border: none;
  padding: 5px;
  border-radius: 6px;
  outline: none;
  background-color: #f1f1f1;
  font-family: inherit;
`;


export const TextArea = styled.textarea`
  padding: 15px;
  //font-size: 12px;
  border: none;
  outline: none;
  border-radius: 10px;
  resize: none;
  color: #FFFFFF;
  background-color: rgba(52, 52, 52, 0.1);
  font-family: inherit;
  //font-size: inherit;
  &::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #777;
  } 
`;


/// FORMS //////////////////////////////////////////////////////////////////////

export const FormInputArea = styled.div`
  display: flex;
  margin: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
  // background-color: #f5f5f5;

`;

export const FormInputLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 35px;
  padding: 0px 10px;
  border-radius: 3px 0px 0px 3px;
  background-color: #f9f9f9;
  color: #000;
  //font-size: 12px;
  font-weight: bold;
`;

export const FormInput = styled.input`
  width: 100%;
  height: 35px;
  padding: 0px 10px;
  border: none;
  border-radius: 0px 5px 5px 0px;
  margin-right: 10px;
  // background-color: #f5f5f5;
  font-size: 11px;
  font-family: inherit;
  outline: none;
`;

export const FormSelect = styled.select`
  width: 100%;
  height: 35px;
  padding: 0px 10px;
  margin-right: 10px;
  border: none;
  border-radius: 0px 5px 5px 0px;
  background-color: #fff;
  font-size: 11px;
  font-family: inherit;
  outline: none;
`;



/// POPUP //////////////////////////////////////////////////////////////////////

export const PopUpArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-top: 100px;
  margin-left: -310px;
  padding: 20px;
  border: 2px solid #ffb400;
  border-radius: 6px;
  opacity: 1;
  content: attr(aria-label);
  pointer-events: all;
  position: relative;
  //transition: 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 255);
  box-shadow: 3px 3px 7px #ccc;
  z-index: 10;
`;


export const PopUpAreaFlex = styled.div`
    padding: 20px;
    border-radius: 7px;
    opacity: 1;
    border: 2px solid orange;
    content: attr(aria-label);
    pointer-events: all;
    position: relative;
    background-color: rgba(255, 255, 255, 255);
    width: 250px;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 3px 7px #ccc;
    z-index: 10;

`;


export const PopUpCloseArea = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #ffb400;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  display: flex;
  background-color: #F9F9F9;
  top: -10px;
  right: -10px;
  cursor: pointer;
`;

export const PopUpBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
`;

export const PopUpBodyScroll = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 8px;
  flex-direction: column;
  overflow-Y: scroll;
  overflow-X: hidden;
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
      background-color: #777;
  }
`;

export const PopUpSelector = styled.div`
  display: flex;
  background: #f0f0f0;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #000;
  justify-content: start;
  align-itens: center;
 // font-weight: bold;
  cursor: pointer;
    &:hover {
    background-color: #e0e0e0;
    transition: 0.25s ease-in-out;
  }
`;



/// MENU //////////////////////////////////////////////////////////////////////

export const TrayMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
`;

export const AreaMenu = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: -30px;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 750px;
  padding: 8px;
  border-radius: 6px;
  background-color: #D0D0D0;
  color: #303030;
`;

export const MenuSpecial = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  padding: 8px;
  margin-right: -30px;
  border-radius: 6px;
  color: #333;
  background-color: var(--primary-color);
`;

export const PopUpAreaMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 208px;
  padding: 20px;
  border: 2px solid #E7E7E7;
  border-radius: 8px;
  margin-top: 120px;
  margin-left: -10px;
  opacity: 1;
  background-color: #CCC;
  content: attr(aria-label);
  pointer-events: all;
  transition: 0.5s ease-in-out;
  /* box-shadow: 3px 3px 7px #ccc; */
  z-index: 50;
`;

export const ButtonMenu = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 192px;
  height: 20px;
  padding: 10px;
  border-radius: 6px;
  margin: 0px 5px;
  /* border: 2px solid #E7E7E7; */
  cursor: pointer;
  &:hover {
    /* background-color: #F0F0F0; */
    /* background-color: #DADAFF; */
    /* background-color: #DEE3FD ; */
    background-color: #EDEDFF;
    transition: 0.25s ease-in-out;
  }
`;

export const TextButtonSubMenu = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
`;

export const ButtonMenuSpecial = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 180px;
  height: 20px;
  padding: 10px;
  border-radius: 4px;
  margin: 0px 5px;
  cursor: pointer;
`;

export const ButtonMenuExit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 4px;
  color: #FFF;
  cursor: pointer;
  &:hover {
    background-color: var(--primary-color);
    transition: 0.25s ease-in-out;
  }
`;


/// USUÁRIO ////////////////////////////////////////////////////////////////////

export const IconArea = styled.div`
  width: 30px;
  height: 36px;
  justify-content: center;
  align-items: center;
`;

export const UserArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 300px;
  margin-right: 15px;
  color: #E0E0E0;
  cursor: pointer;
`;


/// QUEBRAS ////////////////////////////////////////////////////////////////////

/* Linha collapsed que quebrar para uma nova linha */
export const BreakLine = styled.div`
  flex-basis: 100%;
  height: 0;
`;
 
/* Coluna collapsed que quebrar para uma nova coluna */
export const BreakColumn = styled.div`
  flex-basis: 100%;
  width: 0;
`;


/// LOGOS //////////////////////////////////////////////////////////////////////

export const Logo = styled.img`
  width: auto;
  height: 170px;
  margin-top: 150px;
`;

export const HeaderLogo = styled.img`
  position: sticky;
  width: 95px;
  margin: 90px 28px 28px 28px;
`;