import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';
import moment from 'moment'

import * as C from './styled'
import * as G from '../../../styles/global'
import * as M from '../../../styles/modal'

import Api from '../../../services/api'
import FinancialApi from '../../../services/financialApi'
import { UserContext } from '../../../contexts/UserContext';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import BadgeIcon from '@mui/icons-material/Badge';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import ConstructionIcon from '@mui/icons-material/Construction';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';



export const Employees = () => {

  const navigate = useNavigate();
  const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

  const { state: user } = useContext(UserContext)

  const [state, setState] = useState(true)
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [alertError, setAlertError] = useState('')
  const [selectedKey, setSelectedKey] = useState('')
  const [bank, setBank] = useState([])
  const [bankAccountType, setBankAccountType] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingModal, setLoadingModal] = useState(false)
  
  const [popUp, setPopUp] = useState(false)
  const [file, setFile] = useState(false)

  const [stateErrors, setStateErros] = useState(false)



  /// DATATABLE STATES /////////////////////////////////////////////////////////

  const [filteredList, setFilteredList] = useState([])
  const [order, setOrder] = useState('')
  const [activeOrder, setActiveOrder] = useState('')


  
/// DATATABLE FUNCTIONS ////////////////////////////////////////////////////////

  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase()
    let newArray = list.filter((data) => {
        return data.name.toLowerCase().search(value) !== -1 
            || data.department.toLowerCase().search(value) !== -1 
            || data.job_title.toLowerCase().search(value) !== -1 
        })
    setFilteredList(newArray);
  }

  const OrderClick = (field) => {
    setActiveOrder(field)
    let result = filteredList.sort(function(a, b){
      if (order === 'asc'){
        return a[field] < b[field] ? -1 : true
      } else {
        return a[field] > b[field] ? -1 : true
      }
    })
    order === 'asc' ? setOrder('desc') : setOrder('asc')
    setFilteredList(result)
  }
  


/// MASKS //////////////////////////////////////////////////////////////////////

const [cpfNumber, setCpfNumber] = useState('')
const onChangeCpf = ev => {
  const originalValue = unMask(ev.target.value);
  const maskedValue = mask(originalValue, ['999.999.999-99']);
  setCpfNumber(unMask(maskedValue));
}

const [rgNumber, setRgNumber] = useState('')
const onChangeRg = ev => {
  const originalValue = unMask(ev.target.value);
  const maskedValue = mask(originalValue, ['99.999.999-9']);
  setRgNumber(unMask(maskedValue));
}

const [cepNumber, setCepNumber] = useState('')
const onChangeCep = ev => {
  const originalValue = unMask(ev.target.value);
  const maskedValue = mask(originalValue, ['99999-999']);
  setCepNumber(unMask(maskedValue));
}

const [phoneNumber, setPhoneNumber] = useState('')
const onChangePhone = ev => {
  const originalValue = unMask(ev.target.value);
  const maskedValue = mask(originalValue, ['(99) 9999-9999']);
  setPhoneNumber(unMask(maskedValue));
}

const [cellNumber, setCellNumber] = useState('')
const onChangeCell = ev => {
  const originalValue = unMask(ev.target.value);
  const maskedValue = mask(originalValue, ['(99) 9 9999-9999']);
  setCellNumber(unMask(maskedValue));
}

const [pisPasepNumber, setPisPasepNumber] = useState('')
const onChangePisPasep = ev => {
  const originalValue = unMask(ev.target.value);
  const maskedValue = mask(originalValue, ['999.99999.99-9']);
  setPisPasepNumber(unMask(maskedValue));
}







  /// FUNCTIONS ////////////////////////////////////////////////////////////////

  
  const GetEmployee = async () => {
    setLoading(true)
    let res = await FinancialApi.getEmployee();
    setList(res);
    setFilteredList(res);
    setLoading(false)
  }


  const GetSelect =  async (value) => {
    if (value === 1) {
      let res1 = await FinancialApi.getBank()
      setBank(res1)
    } else if (value === 2) {
      let res2 = await FinancialApi.getBankAccountType()
      setBankAccountType(res2)
    } 
  }
  

  const onSubmit = async (data) => {
    data["cpf"] = cpfNumber
    data["rg"] = rgNumber
    data["address_zip_code"] = cepNumber
    data["contact_phone"] = phoneNumber
    data["contact_cell_phone_1"] = cellNumber
    data["pis_pasep"] = pisPasepNumber
    // console.log("onSubmit: ", data)

    switch (modalType) {
      case 1:
        const userAdd = ({
          created_by: user.id,
          changed_by: user.id
        })
        const add = ({...data, ...userAdd})
        let res1 = await FinancialApi.postEmployee(add)
        // console.log("addEmployee > res1 : ", res1)
      break;

      case 2:
        const userUpd = ({
          changed_by: user.id
        })
        const upd = ({...data, ...userUpd})
        let res2 = await FinancialApi.postEmployee(upd)
        // console.log("updEmployee > res2 : ", res2)
      break;

      default:
      break;
    }
    CloseModalClick()
  };


  const DelEmployee = async (id) => {
    let res = await FinancialApi.delEmployee(id);
    GetEmployee();
  }


  const ActEmployee = async (id, estado) => {
    const data = ({
      id: id, 
      state: estado, 
      changed_by: user.id
    })
    let res = await FinancialApi.postEmployee(data);
    // console.log("ActEmployee > res : ", res)
    GetEmployee();
  }

  
  const AddClick = () => {
    setModalType(1)
    setModal(true)
  }  

  const EditClick = (key) => {
    setSelectedKey([key])
    setCpfNumber((filteredList[key].cpf === null || filteredList[key].cpf === undefined) ? '' : filteredList[key].cpf)
    setRgNumber((filteredList[key].rg === null || filteredList[key].rg === undefined) ? '' : filteredList[key].rg)
    setCepNumber((filteredList[key].address_zip_code === null || filteredList[key].address_zip_code === undefined) ? '' : filteredList[key].address_zip_code)
    setPhoneNumber((filteredList[key].contact_phone === null || filteredList[key].contact_phone === undefined) ? '' : filteredList[key].contact_phone)
    setCellNumber((filteredList[key].contact_cell_phone_1 === null || filteredList[key].contact_cell_phone_1 === undefined) ? '' : filteredList[key].contact_cell_phone_1)
    setPisPasepNumber((filteredList[key].pis_pasep === null || filteredList[key].pis_pasep === undefined) ? '' : filteredList[key].pis_pasep)
    setModalType(2)
    setModal(true)
  }

  const DelClick = async (key) => {
    setSelectedKey([key])
    setModalType(3)
    setModal(true)
  }

  const ActClick = (key) => {
    setSelectedKey([key])
    setModalType(4)
    setModal(true)
  }

  const CloseModalClick = () => {
    setLoading(true)
    setSelectedKey('')
    setCpfNumber('')
    setRgNumber('')
    setCepNumber('')
    setPhoneNumber('')
    setCellNumber('')
    setPisPasepNumber('')
    setStateErros(false)
    setAlertError('')
    GetEmployee()
    setModal(false)
    setModalType('')
    reset()
    setLoading(false)
  }

  const CloseModalClick2 = () => {
    setLoading(true)
    setSelectedKey('')
    setCpfNumber('')
    setRgNumber('')
    setCepNumber('')
    setPhoneNumber('')
    setCellNumber('')
    setPisPasepNumber('')
    setStateErros(false)
    setAlertError('')
    setModal(false)
    setModalType('')
    reset()
    setLoading(false)
  }


  const upLoad = async () => {
    let res1 = await FinancialApi.uploadFile(file)
    let res2 = await FinancialApi.updUser({id: user.id, photo: file.name})
    setPopUp(false)
    // window.location.reload(false);
  }



  
/// DETECTA A TECLA ESCAPE (ESC) ///////////////////////////////////////////////

  document.onkeydown = function (e) {
    if (e.key === 'Escape') {
      CloseModalClick();
    }
  }



/// USE EFECT //////////////////////////////////////////////////////////////////

  useEffect(()=>{ 
    GetEmployee();
  }, [state]);

;



/// CONSOLE LOG ////////////////////////////////////////////////////////////////

  //console.log("filteredList : ", filteredList);

  
  



  return (
    <G.Container>

      <G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <BadgeIcon style={{ fontSize: '32px',margin: '0px 0px 0px 0px' }}/>
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <G.MainTitle style={{ fontSize: 'var(--font-size-title)' }}>Funcionários</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>
          
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '280px', justifyContent: 'center' }}>
          <G.SearchArea style={{ height: '40px', color: '#444', justifyContent: 'space-between' }}>
            <G.SearchIconArea>
              <SearchIcon />
            </G.SearchIconArea>
            <G.SearchInput id='search' onChange={(event) => handleSearch(event)} />
          </G.SearchArea>        
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
          <G.Button style={{ backgroundColor: '#2E8B57', justifyContent: 'space-around' }} onClick={AddClick}>
            <AddIcon fontSize='small'/>
            {'Adicionar'}
          </G.Button>
        </G.HeaderTitleArea>
      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && filteredList.length === 0) &&
          <G.OptionsArea style={{justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem itens para a seleção atual! 
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && filteredList.length > 0)  && 
          <>
            <G.Box>

              <G.TableHeader>
                <G.OptionsArea style={{ padding: '0px' }}>
                  <G.FieldArea size={'40px'} style={{ padding: '0px', justifyContent: 'center' }}><G.DistacInfoText></G.DistacInfoText></G.FieldArea>
                  <G.FieldArea size={'60px'} style={{ cursor: 'pointer', padding: '0px 0px 0px 8px' }} onClick={() => OrderClick('id')}><G.DistacInfoText>ID</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'id') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'296px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('name')}><G.DistacInfoText>Nome</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'name') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'200px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('department')}><G.DistacInfoText>Departamento</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'department') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>
                  <G.FieldArea size={'200px'} style={{ cursor: 'pointer' }} onClick={() => OrderClick('job_title')}><G.DistacInfoText>Função</G.DistacInfoText>
                    {(activeOrder === '' || activeOrder === 'job_title') &&
                      <>
                        {order === 'asc' ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/> }
                      </>
                    }
                  </G.FieldArea>

                  <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}><G.DistacInfoText>Ações</G.DistacInfoText></G.FieldArea>
                </G.OptionsArea>
              </G.TableHeader>

              {(loading === true && filteredList.length === 0 ) &&  
                <G.Loading2 src={"/loading.svg"} />
              }

              <G.TableBody style={{ justifyContent: 'start', marginTop: '0px'}}>
                {(loading === false && filteredList.length === 0) && 
                  <G.Text style={{ height: '40px', justifyContent: 'center', alignItems: 'center', marginTop: '20px', fontSize: '13px' }}>
                    *** Não existem itens a exibir. ***
                  </G.Text>
                }
                {(loading === false && filteredList.length > 0) &&
                  <>
                    {filteredList.map((item, key) => (
                      <G.TableArea key={key}>
                        
                        <G.FieldArea size={'40px'} style={{ padding: '0px', justifyContent: 'center' }}>

                          {/* <G.Text>
                            <C.ContactTypeIconArea style={{ border:  item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839' }}>
                              <BadgeIcon style={{ fontSize: '23px', color: '#000' }} />
                            </C.ContactTypeIconArea>
                          </G.Text> */}

                          {item.photo !== null
                            ? <G.Image 
                              src={`${process.env.REACT_APP_BACKEND}/download?file=${item.photo}`} 
                              style={{ minWidth: '32px', height: '32px', borderRadius: '50%', border: item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839' }}
                            />
                            : <AccountCircleIcon style={{ minWidth: '36px', height: '36px', color: 'var(--icon-color)',borderRadius: '50%', border:  item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839' }}/>
                          }

                        </G.FieldArea>

                        {/* <G.FieldArea size={'40px'}>
                          {item.photo !== null
                            ? <G.Image 
                              src={`${process.env.REACT_APP_BACKEND}/download?file=${item.photo}`} 
                              style={{ minWidth: '32px', height: '32px', borderRadius: '50%', border: item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839', marginRight: '10px', padding: '3px' }}
                            />
                            : <G.Image 
                              src={'/person.svg'} 
                              style={{ minWidth: '32px', height: '32px', borderRadius: '50%', border: item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839', marginRight: '10px', padding: '3px' }}
                            />
                          }
                        </G.FieldArea> */}
                        
                        {/* <G.FieldArea size={'40px'}>
                          {(item.photo !== null) && 
                            <G.Image 
                              src={`${process.env.REACT_APP_BACKEND}/download?file=${item.photo}`} 
                              style={{ minWidth: '30px', height: '30px', borderRadius: '50%', border: item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839', marginRight: '10px', padding: '3px' }}
                            />
                          }
                          {(item.photo === null) && 
                            <BadgeIcon style={{ minWidth: '30px', height: '30px', borderRadius: '50%', color: '#333', border: item.state === 1 ? '2px solid #2E8B57' : '2px solid #E95839', marginRight: '10px', padding: '3px' }}/>
                          }
                        </G.FieldArea> */}

                        <G.FieldArea size={'60px'} style={{ padding: '0px 0px 0px 8px' }}><G.Text>{item.id}</G.Text></G.FieldArea>
                        <G.FieldArea style={{justifyContent: 'start'}} size={'296px'}><G.Text>{item.name}</G.Text></G.FieldArea>
                        <G.FieldArea size={'200px'}><G.Text>{item.department}</G.Text></G.FieldArea>
                        <G.FieldArea size={'200px'}><G.Text>{item.job_title}</G.Text></G.FieldArea>

                        <G.FieldArea size={'112px'} style={{ justifyContent: 'flex-start' }}>
                          <G.IconButton onClick={() => EditClick(key)} style={{ backgroundColor: '#1E90FF', margin: '0px 1px' }}>
                            <EditIcon fontSize="small" />
                          </G.IconButton>
                          <G.IconButton onClick={() => ActClick(key)} style={{ backgroundColor: item.state === 0 ? '#2E8B57' : '#555', margin: '0px 1px' }}>
                            {item.state === 0 ? <CheckIcon fontSize="small"/> : item.state === 1 ? <BlockIcon fontSize="small"/> : <PriorityHighIcon fontSize="small"/>}
                          </G.IconButton>
                          <G.IconButton onClick={() => DelClick(key)} style={{ backgroundColor: '#E95839', margin: '0px 1px'}}>
                            <DeleteIcon fontSize="small"/>
                          </G.IconButton>
                        </G.FieldArea>
                      </G.TableArea>
                    ))}
                  </>
                }
              </G.TableBody>

            </G.Box>
          </>
        }
      </G.Body>

      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

      {modal !== false &&
        <M.ModalContainer>
          <M.ModalContentLarge style={{ justifyContent: 'center'}}>

            <M.ModalHeader 
              style={{ 
                backgroundColor: 
                  modalType === 1 ? '#BDF5D6' : modalType === 2 
                  ? '#C8E4FF' : modalType === 3 
                  ? '#FCAC9B' : (filteredList[selectedKey].state === 0 
                  ? '#BDF5D6' : '#ccc'), 
                justifyContent: 'space-between', 
                bordeRadius: '15px' 
              }}>
              <C.ModalTitle>{modalType === 1 ? 'Adicionar Funcionário' : modalType === 2 ? 'Editar Funcionário' : modalType === 3 ? 'Excluir Funcionário' : (filteredList[selectedKey].state === 0 ? 'Ativar Funcionário' : 'Desativar Funcionário')}</C.ModalTitle>
                <G.InfoArea>
                  <HighlightOffIcon fontSize="large" style={{ cursor: 'pointer', color: '#333' }} onClick={CloseModalClick} />
                </G.InfoArea>
            </M.ModalHeader>

            <M.ModalBody style={{ width: '1000px', flexDirection: 'column', alignItems: 'start', marginTop: '5px'}}>

              {(modalType === 2 || modalType === 3) && 
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={modalType === 1 ? '' :  filteredList[selectedKey].id} {...register("id")} />
              }

              {(modalType === 1 || modalType === 2) &&
                <G.BoxBodyScroll style={{ display: 'flex', margin: '0px 0px', flexWrap: 'wrap',  justifyContent: 'start' }}>

                  <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: '100%', height: '128px', justifyContent: 'center', alignItems: 'start' }}>

                    {modalType === 1 && 
                      <div style={{ display: 'flex', width: '112px', height: '112px', justifyContent: 'center', alignItems: 'center', padding: '0px', marginLeft: '7px' }}>
                        <G.Image src={'/person.svg'}  style={{ minWidth: '100px', height: '100px', borderRadius: '50%', border: '3px solid #2E8B57', backgroundColor: '#F5F5F5' }}/>
                      </div>
                    }

                    {modalType === 2 && 
                      <div style={{ display: 'flex', width: '112px', height: '112px', justifyContent: 'center', alignItems: 'center', padding: '0px', marginLeft: '7px' }}>
                        {filteredList[selectedKey].photo !== null
                          ? <G.Image 
                              src={`${process.env.REACT_APP_BACKEND}/download?file=${filteredList[selectedKey].photo}`} 
                              style={{ width: '100px', height: '100px', borderRadius: '50%', border: filteredList[selectedKey].state === 1 ? '3px solid #2E8B57' : '3px solid #E95839' }}
                            />
                          : <G.Image 
                              src={'/person.svg'} 
                              style={{ minWidth: '100px', height: '100px', borderRadius: '50%', border: filteredList[selectedKey].state === 1 ? '3px solid #2E8B57' : '3px solid #E95839', backgroundColor: '#F5F5F5' }}
                            />
                        }
                      </div>
                    }

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '828px', height: '110px', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '20px' }}>                 
                      <G.FormInputArea style={{ width: '480px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                          Nome
                        </G.FormInputLabel>
                        <G.FormInput autoFocus defaultValue={modalType === 1 ? '' : filteredList[selectedKey].name} {...register("name", { required: true })} />
                      </G.FormInputArea>
                      
                      <G.BreakLine />

                      <G.FormInputArea style={{ width: '380px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                          Departamento
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].department} {...register("department")} />
                      </G.FormInputArea>

                      <G.FormInputArea style={{ width: '380px' }}>
                        <G.FormInputLabel style={{ borderLeft: '3px solid #2E8B57' }}>
                          Função
                        </G.FormInputLabel>
                        <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].job_title} {...register("job_title")} />
                      </G.FormInputArea>
                    </div>

                    <G.BreakColumn style={{ width: '300px' }}/>
                    
                  </div>

                  <G.BreakLine />

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', margin: '16px 0px 16px -8px' }}>
                    {(Object.keys(errors).length > 0 || stateErrors) &&  
                      <div role="alert" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '92%', padding: '7px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                        <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                        <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}</div>
                        <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                      </div>
                    }
                  </div>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Endereço
                  </div>

                  <G.FormInputArea style={{ width: '592px' }}>
                    <G.FormInputLabel>
                      Endereço
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].address} {...register("address", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '360px' }}>
                    <G.FormInputLabel>
                      Complemento
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].address_complement} {...register("address_complement", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '480px' }}>
                    <G.FormInputLabel>
                      Bairro
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].address_district} {...register("address_district", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '180px' }}>
                    <G.FormInputLabel>
                      CEP
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='cepNumber'
                      placeholder=''
                      value={mask(cepNumber, ['99999-999'])}
                      onChange={onChangeCep}
                      //defaultValue={modalType === 1 ? '' : filteredList[selectedKey].address_zip_code} {...register("address_zip_code", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '480px' }}>
                    <G.FormInputLabel>
                      Cidade
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].address_city} {...register("address_city", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '120px' }}>
                    <G.FormInputLabel>
                      UF
                    </G.FormInputLabel>
                    <G.FormInput maxLength={2} defaultValue={modalType === 1 ? '' : filteredList[selectedKey].address_state_acronym} {...register("address_state_acronym", { setValueAs: v => v.toUpperCase() })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '340px' }}>
                    <G.FormInputLabel>
                      País
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].address_country} {...register("address_country", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  {/* <G.BreakLine style={{ height: '12px' }}/> */}

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Contatos
                  </div>

                  <G.FormInputArea style={{ width: '220px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Telefone
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='phoneNumber'
                      placeholder=''
                      value={mask(phoneNumber, ['(99) 9999-9999'])}
                      onChange={onChangePhone}
                      //defaultValue={modalType === 1 ? '' : filteredList[selectedKey].contact_phone} {...register("contact_phone", { setValueAs: v => v === '' ? '' : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '140px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Ramal
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].contact_phone_extension} {...register("contact_phone_extension", { setValueAs: v => v === '' ? '' : v })} />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '220px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Celular
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='cellNumber'
                      placeholder=''
                      value={mask(cellNumber, ['(99) 9 9999-9999'])}
                      onChange={onChangeCell}
                      //defaultValue={modalType === 1 ? '' : filteredList[selectedKey].contact_cell_phone_1} {...register("contact_cell_phone_1", { setValueAs: v => v === '' ? '' : v })} 
                    />
                  </G.FormInputArea>

                  <G.FormInputArea style={{ width: '344px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      E-mail
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].contact_email} {...register("contact_email", { setValueAs: v => v === '' ? '' : v })} />
                  </G.FormInputArea>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Dados Pessoais
                  </div>

                  <G.FormInputArea style={{ width: '230px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      CPF
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='cpfNumber'
                      placeholder=''
                      value={mask(cpfNumber, ['999.999.999-99'])}
                      onChange={onChangeCpf}
                      //defaultValue={modalType === 1 ? '' : filteredList[selectedKey].cpf} {...register("cpf")} 
                    />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '230px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      RG
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='rgNumber'
                      placeholder=''
                      value={mask(rgNumber, ['999.999.999-99'])}
                      onChange={onChangeRg}
                      //defaultValue={modalType === 1 ? '' : filteredList[selectedKey].rg} {...register("rg")} 
                    />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '230px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      CNH
                    </G.FormInputLabel>
                    <G.FormInput maxLength={11} defaultValue={modalType === 1 ? '' : filteredList[selectedKey].cnh} {...register("cnh")} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '232px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Data Nasc.
                    </G.FormInputLabel>
                    <G.FormInput type="date" defaultValue={modalType === 1 ? '' : moment(filteredList[selectedKey].birthdate).format('YYYY-MM-DD')} {...register("birthdate", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  
                  <G.FormInputArea style={{ width: '352px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Naturalidade
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].place_of_birth} {...register("place_of_birth", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '352px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Nacionalidade
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].nationality} {...register("nationality", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.BreakLine />

                  <G.FormInputArea style={{ width: '280px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      C. Trab. Núm.
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].work_card_number} {...register("work_card_number", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '180px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      C. Trab. Série
                    </G.FormInputLabel>
                    <G.FormInput maxLength={4} defaultValue={modalType === 1 ? '' : filteredList[selectedKey].work_card_serie} {...register("work_card_serie", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '160px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      C. Trab. UF
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].work_card_state_acronym} {...register("work_card_state_acronym", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '280px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      PIS / PASEP
                    </G.FormInputLabel>
                    <G.FormInput 
                      type='text'
                      name='pisPasepNumber'
                      placeholder=''
                      value={mask(pisPasepNumber, ['999.99999.99-9'])}
                      onChange={onChangePisPasep}
                      //defaultValue={modalType === 1 ? '' : filteredList[selectedKey].pis_pasep} {...register("pis_pasep", { setValueAs: v => v === '' ? null : v })} 
                    />
                  </G.FormInputArea>

                  <G.BreakLine />

                  <G.FormInputArea style={{ width: '320px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Tipo Doc.
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].document_type} {...register("document_type", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '320px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #1E90FF' }}>
                      Cód. Doc.
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].document_code} {...register("document_code", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  {/* <hr style={{ width: '100%', flexDirection: 'column', margin: '10px 0px', backgroundColor: '#777' }} /> */}

                  <div style={{ display: 'flex', width: '956px', height: '35px', margin: '0px 0px 6px 7px', alignItems: 'end', color: '#888', borderBottom: '1px solid #BBB' }}>
                    Dados Bancários
                  </div>
                  
                  <G.FormInputArea style={{ width: '300px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Tipo Conta
                    </G.FormInputLabel>
                    <G.FormSelect {...register("bank_account_type_id", { setValueAs: v => v === '' ? null : v })}  onMouseDown={() => GetSelect(2)}>
                      <option disabled selected value={modalType === 1 ? '' : filteredList[selectedKey].bank_account_type_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].bank_account_type_name}</option>
                      {bankAccountType.map(i => (
                        <option key={i.id} value={i.id}>{i.name}</option>
                      ))}
                    </G.FormSelect>
                    {/* {errors.bank_account_type_id && <span style={{ fontSize: '12px', color: 'red', }}>(*) Selecione o Tipo da Conta.</span>} */}
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '560px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Banco
                    </G.FormInputLabel>
                    <G.FormSelect {...register("bank_id", { setValueAs: v => v === '' ? null : v })} onMouseDown={() => GetSelect(1)}>
                      <option disabled selected value={modalType === 1 ? '' :  filteredList[selectedKey].bank_id}>{modalType === 1 ? 'Selecione' : filteredList[selectedKey].bank_name}</option>
                      {bank.map(i => (
                        <option key={i.id} value={i.id}>{i.name}</option>
                      ))}
                    </G.FormSelect>
                    {/* {errors.bank_name && <span style={{ fontSize: '12px', color: 'red', }}>(*) Selecione o Banco.</span>} */}
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '200px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Agência
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].bank_branch} {...register("bank_branch", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '240px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Conta
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].bank_account_number} {...register("bank_account_number", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>
                  <G.FormInputArea style={{ width: '406px' }}>
                    <G.FormInputLabel style={{ borderLeft: '3px solid #FCC703' }}>
                      Código PIX
                    </G.FormInputLabel>
                    <G.FormInput defaultValue={modalType === 1 ? '' : filteredList[selectedKey].pix_code} {...register("pix_code", { setValueAs: v => v === '' ? null : v })} />
                  </G.FormInputArea>

                  <G.BreakLine style={{ height: '12px' }}/>

                  <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '10px', marginBottom: '10px' }}>
                    <G.FormInputLabel style={{ border: 'none', width: '936px', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                      Observações
                    </G.FormInputLabel>
                    <G.TextArea 
                      defaultValue={modalType === 1 ? '' : filteredList[selectedKey].note} 
                      {...register("note", { setValueAs: v => v === '' ? null : v })} 
                      style={{ width: '916px', height: '150px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                    >
                      {/* {modalType === 1 ? '' :  filteredList[selectedKey].note} */}
                    </G.TextArea>
                  </G.FieldArea>

                </G.BoxBodyScroll>
              }
            </M.ModalBody>
            
            

            {(modalType === 3) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja excluir o Funcionário:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].name}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            {(modalType === 4) && 
              <M.ModalBody style={{ flexDirection: 'column', alignItems: 'center' }}>
                <G.Text style={{ margin: '10px 0px' }}>
                  Deseja {filteredList[selectedKey].state === 0 ? 'ativar' : 'desativar'} o Funcionário:
                </G.Text>
                <G.FormInputArea style={{  width: '90%', justifyContent: 'center' }}>
                  <G.FormInputLabel style={{ width: '100%', justifyContent: 'center', marginBottom: '10px', border: 'none', fontSize: '12px', fontWeight: 'bold' }}>
                    {filteredList[selectedKey].name}
                  </G.FormInputLabel>
                </G.FormInputArea>
              </M.ModalBody>
            }

            <M.ModalFooter style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {alertError !== '' &&
                <C.Alert><WarningIcon style={{marginRight: '10px', fontSize: '30px', color: '#333' }} />{alertError}</C.Alert>
              }
              
              {(modalType === 1 || modalType === 2) &&
                <>
                  <G.Button style={{backgroundColor: '#E95839'}} onClick={() => CloseModalClick2()}>Fechar</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57'}} onClick={() => handleSubmit(onSubmit)()}>Enviar</G.Button>
                </>
              }

              {modalType === 3 && 
                <>
                  <G.Button style={{backgroundColor: '#E95839', width: '120px'}} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{backgroundColor: '#2E8B57', width: '120px'}} onClick={() => DelEmployee(filteredList[selectedKey].id) + CloseModalClick()}>Sim</G.Button>
                </>
              }

              {modalType === 4 && 
                <>
                  <G.Button style={{ backgroundColor: '#E95839', width: '120px' }} onClick={() => CloseModalClick2()}>Não</G.Button>
                  <G.Button style={{ backgroundColor: '#2E8B57', width: '120px' }} onClick={() => ActEmployee(filteredList[selectedKey].id, filteredList[selectedKey].state === 0 ? 1 : 0) + CloseModalClick2()}>Sim</G.Button>
                </>
              }
            </M.ModalFooter>

          </M.ModalContentLarge>
        </M.ModalContainer>
      }
    </G.Container>
  );
}

