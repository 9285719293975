import styled from 'styled-components';


/// GENERAL ///////////////////////////////////////////////////////////////////

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const ModalContentSmall = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
  max-height: 550px;
  padding: 12px;
  border-radius: 12px;
  overflow: none;
  background-color: #e9e9e9;
`;

export const ModalContentLarge = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 630px;
  padding: 15px;
  border-radius: 3px;
  box-shadow: 3px 3px 7px #333;
  overflow: none;
  background-color: #e9e9e9;
`;

export const ModalContent = styled.div`
  display: flex;
  color: 000;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 0px;
  border-radius: 3px;
  box-shadow: 3px 3px 7px #333;
  overflow: none;
  background-color: #fff;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 96%;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 2px 2px 4px #ccc;
`;

export const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  `;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  margin-top: 5px;
  width: 100%;
  padding: 10px;
`;

export const ModalArea = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 95%;
min-height: 40px;
padding: 20px 10px;
border-radius: 5px;
margin-top: -3px;
box-shadow: 0px 0px 5px #ccc;
background-color: #f9f9f9;
`;

export const ModalTitle = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
padding: 4px;
border-radius: 8px;
/* margin-left: 16px; */
font-size: 16px;
font-weight: bold;
color: #000;
`;