import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  `;

// export const IconArea = styled.div`
//   display: flex;
//   color: #E0E0E0;
//   font-size: 13px;
//   align-items: center;
//   justify-content: space-evenly;
//   width: 100px;
//   margin-right: 15px;
// `;

export const IconArea = styled.div`
  width: 30px;
  height: 36px;
  justify-content: center;
  align-items: center;
`;

export const UserArea = styled.div`
  display: flex;
  color: #E0E0E0;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  margin-right: 15px;
  cursor: pointer;
`;

export const UserInfoText = styled.div`
  display: flex;
  margin-left: 5px;
  font-size: 14px;
`;

export const ContactTypeArea = styled.div`
  height: 40px;
  border-radius: 10px;
  padding: 0px 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  width: 170px;
  justify-content: space-around;
`;

export const ContactTypeIconArea = styled.div`
  height: 40px;
  display: flex;
  color: #E0E0E0;
  align-items: center;
  min-width: 20px;
  justify-content: center;
  font-size: 13px;
`;

export const ContactTypeIcon = styled.div`
  padding: 5px;
  width: 10px;
  height: 10px;
  color: #000;
  margin-left: -15px;
  margin-bottom: -12px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  background-color: #FCC703;
  font-weight: bold;
`;

export const Logo = styled.img`
  position: sticky;
  width: 110px;
  margin: 108px 28px 28px 28px;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  padding: 7px;
  width: 160px;
  height: 20px;
  margin-top: 15px;
  color: #333;
  border-bottom: 2px dotted #333;
  cursor: pointer;
  &:hover {
      border-bottom: 2px solid #333;
  }
`;

export const Info = styled.div`
  font-size: 16px;
  color: #333;
  font-weight: normal;
  padding: 10px 0px;
`;
