import styled from 'styled-components';


export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: '1000px';
  min-height: 64px;
  background-color: #f9f9f9;
  border: 1px solid: #E0E0E0;
  border-radius: 6px;
  margin: 24px 4px 4px 4px;
  padding: 0px 10px;
  box-shadow: 0px 0px 4px #ccc;
`;

export const HeaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  min-width: 240px;
  height: 40px;
  padding: 4px;
  border-radius: 6px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  margin: 0px 0px 0px 6px;
  font-size: 22px;
  font-weight: bold;
  color: #222;
`;
