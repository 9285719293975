import React, { useState } from 'react';
import * as S from './styled';


import SearchIcon from '@mui/icons-material/Search';


export const Search = ({ children }) => {

	return (
    <S.SearchArea>
      <S.SearchIconArea>
        <SearchIcon />
      </S.SearchIconArea>
      <S.SearchInputArea>
        {children}
      </S.SearchInputArea>
    </S.SearchArea>       
	);
}
