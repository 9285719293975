export const initialState = {
    id: '',
    user: '',
    first_name: '',
    last_name: '',
    email: '',
    photo: '',

};

export const UserReducer = (state, action) => {
    
    switch (action.type) {
        case 'setUser':
                return { ...state, 
                id: action.payload.id, 
                user: action.payload.user, 
                first_name: action.payload.first_name,
                last_name: action.payload.last_name,
                email: action.payload.email,
                photo: action.payload.photo,
            }


        default:
        return state;
    }
}