import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { mask, unMask } from 'remask';
import moment from "moment";
import 'moment/locale/pt-br';

import * as C from './styled'
import * as G from '../../../styles/global'
import * as M from '../../../styles/modal'

import Api from '../../../services/api'
import FinancialApi from '../../../services/financialApi'
import { UserContext } from '../../../contexts/UserContext';

import AddIcon from '@mui/icons-material/Add';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import BlockIcon from '@mui/icons-material/Block';
import BusinessIcon from '@mui/icons-material/Business';
import CheckIcon from '@mui/icons-material/Check';
import CopyrightIcon from '@mui/icons-material/Copyright';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FactoryIcon from '@mui/icons-material/Factory';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import StoreIcon from '@mui/icons-material/Store';
import TaskIcon from '@mui/icons-material/Task';
import WarningIcon from '@mui/icons-material/Warning';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



/// DATATABLE ICONS ////////////////////////////////////////////////////////////

import SearchIcon from '@mui/icons-material/Search';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export const MyCompany = () => {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: { errors }, reset} = useForm({ mode: 'resolver' });

    const { state: user } = useContext(UserContext)

    const [state, setState] = useState('')
    const [list, setList] = useState([]);
    const [myCompanyData, setMyCompanyData] = useState([])
    const [verify, setVerify] = useState(false)
    const [vrfData, setVrfData] = useState(false)

    const [loading, setLoading] = useState(true)
    const [stateErrors, setStateErros] = useState(false);


    
/// MASK ///////////////////////////////////////////////////////////////////////

  const [cnpjNumber, setCnpjNumber] = useState('')
  const onChangeCnpj = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99.999.999/9999-99']);
    setCnpjNumber(unMask(maskedValue));
  }


  const [cepNumber, setCepNumber] = useState('')
  const onChangeCep = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['99999-999']);
    setCepNumber(unMask(maskedValue));
  }
  

  const [phoneNumber, setPhoneNumber] = useState('')
  const onChangePhone = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9999-9999']);
    setPhoneNumber(unMask(maskedValue));
  }


  const [cellNumber, setCellNumber] = useState('')
  const onChangeCell = ev => {
    const originalValue = unMask(ev.target.value);
    const maskedValue = mask(originalValue, ['(99) 9 9999-9999']);
    setCellNumber(unMask(maskedValue));
  }


////////////////////////////////////////////////////////////////////////////////



    


/// FUNCTIONS ////////////////////////////////////////////////////////////////


  const GetMyCompany = async () => {
    setLoading(true)

    let res = await FinancialApi.getMyCompany()
    setList(res)

    if (res.length === 0) {
      setVerify(false)
      
    } else {
      setVerify(true)
      setVrfData(true)
    }
    
    setMyCompanyData(res)

    setCnpjNumber((res.length === 0 || res[0].cnpj === null || res[0].cnpj === undefined) ? '' : res[0].cnpj)
    setCepNumber((res.length === 0 || res[0].address_zip_code === null || res[0].address_zip_code === undefined) ? '' : res[0].address_zip_code)
    setPhoneNumber((res.length === 0 || res[0].contact_phone === null || res[0].contact_phone === undefined) ? '' : res[0].contact_phone)
    setCellNumber((res.length === 0 || res[0].contact_cell_phone === null || res[0].contact_cell_phone === undefined) ? '' : res[0].contact_cell_phone)

    setLoading(false)
  }



  const onSubmit = async (data, option) => {

    data["cnpj"] = cnpjNumber;
    data["address_zip_code"] = cepNumber;
    data["contact_phone"] = phoneNumber;
    data["contact_cell_phone"] = cellNumber;

    if (cnpjNumber === null || cnpjNumber === '' 
      || cepNumber === null || cepNumber === ''
      || phoneNumber === null || phoneNumber === ''
      || cellNumber === null || cellNumber === ''
    ) {
      setStateErros(true)
    } else {
      setStateErros(false)

      switch (option) {
        
        case 1:
          const userAdd = ({
            created_by: user.id,
            changed_by: user.id
          })
          const add = ({...data, ...userAdd})
          let res1 = await FinancialApi.postMyCompany(add)
          break;

        case 2:
          const userUpd = ({
            changed_by: user.id
          })
          const upd = ({...data, ...userUpd})
          let res2 = await FinancialApi.postMyCompany(upd)
          break;

        default:
          break;
      }

      GetMyCompany()

    }
  };






/// USE EFECT //////////////////////////////////////////////////////////////////

    useEffect(()=>{
      GetMyCompany(); 
    }, [state]);


    
/// CONSOLE LOG ////////////////////////////////////////////////////////////////










  return (
    <G.Container>

      <G.Header>
        <G.HeaderTitleArea style={{ width: '240px' }}>
          <StoreIcon style={{ fontSize: '32px',margin: '0px 0px 0px 0px' }} />
          <hr style={{ height: '72%', flexDirection: 'row', padding: '0px', margin: '0px 8px', border: '0.04rem solid #333', borderRadius: '4px' }} />
          <G.MainTitle style={{ fontSize: 'var(--font-size-title)' }}>Minha Empresa</G.MainTitle>
        </G.HeaderTitleArea>
        <G.HeaderTitleArea style={{ width: '320px' }}>
          
        </G.HeaderTitleArea>

        <G.HeaderTitleArea style={{ width: '120px', justifyContent: 'end' }}>
        {(myCompanyData.length === 0 && verify === false) &&
            <G.Button 
              style={{ backgroundColor: 'var(--primary-color)', justifyContent: 'space-around' }} 
              onClick={() => setVerify(true)}
            >
              <AddIcon fontSize='small'/>
              {'Adicionar'}
            </G.Button>
          }
          {(myCompanyData.length === 0 && verify === true) &&
            <G.Button 
              style={{ backgroundColor: 'var(--edit-color)', justifyContent: 'space-around' }} 
              onClick={() => handleSubmit(onSubmit)(1)}
            >
              <CheckIcon fontSize='small'/>
              {'Cadastrar'}
            </G.Button>
          }
          {(myCompanyData.length > 0 && verify === true) &&
            <G.Button 
              style={{ backgroundColor: 'var(--success-color)', justifyContent: 'space-around' }} 
              onClick={() => handleSubmit(onSubmit)(2)}
            >
              <CheckIcon fontSize='small'/>
              {'Atualizar'}
            </G.Button>
          }
        </G.HeaderTitleArea>
      </G.Header>

      <G.Body>

        {loading &&  
          <G.Logo src={"/loading.svg"} />
        }

        {(!loading && verify === false) &&
          <G.OptionsArea style={{ justifyContent: 'center', display: 'flex', marginTop: '120px', flexDirection: 'column' }}>
            <ErrorOutlineRoundedIcon style={{ fontSize: '80px', color: '#aaa' }}/>
            <G.MainTitle style={{ fontSize: '16px', borderBottom: '1px solid #aaa', paddingBottom: '7px', marginTop: '10px', color: '#aaa' }}>
              Não existem dados cadastrados!
            </G.MainTitle>
          </G.OptionsArea>
        }

        {(!loading && verify === true) && 
          <>
            <G.Box style={{ width: '984px' }}>

              <G.BoxBody style={{ width: '960px', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '12px', flexWrap: 'wrap', marginTop: '5px' }}>
                
                <G.FormInput style={{ width: '80px'}} type="hidden" defaultValue={!vrfData ? '' : myCompanyData[0].id} {...register("id", { setValueAs: v => v === '' ? null : v })} />

                <G.FormInputArea style={{ width: '464px' }}>
                  <G.FormInputLabel>
                    Nome
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].company_name} {...register("company_name", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '464px' }}>
                  <G.FormInputLabel>
                    Fantasia
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].trade_name} {...register("trade_name", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '304px' }}>
                  <G.FormInputLabel>
                    CNPJ
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='cnpjNumber'
                    placeholder=''
                    value={mask(cnpjNumber, ['99.999.999/9999-99'])}
                    onChange={onChangeCnpj}
                  />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '306px' }}>
                  <G.FormInputLabel>
                    I.E.
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].state_tax_id_number} {...register("state_tax_id_number", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '306px' }}>
                  <G.FormInputLabel>
                    I.M.
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].city_tax_id_number} {...register("city_tax_id_number", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '212px' }}>
                  <G.FormInputLabel>
                    Telefone
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='phoneNumber'
                    placeholder=''
                    value={mask(phoneNumber, ['(99) 9999-9999'])}
                    onChange={onChangePhone}
                  />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '124px' }}>
                  <G.FormInputLabel>
                    Ramal
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].contact_phone_extension} {...register("contact_phone_extension", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '212px' }}>
                  <G.FormInputLabel>
                    Celular
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='cellNumber'
                    placeholder=''
                    value={mask(cellNumber, ['(99) 9 9999-9999'])}
                    onChange={onChangeCell}
                  />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '352px' }}>
                  <G.FormInputLabel>
                    E-mail
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].contact_email} {...register("contact_email", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '504px' }}>
                  <G.FormInputLabel>
                    Endereço
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].address} {...register("address", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '424px' }}>
                  <G.FormInputLabel>
                    Complemento
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].address_complement} {...register("address_complement", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '304px' }}>
                  <G.FormInputLabel>
                    Bairro
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].address_district} {...register("address_district", { required: true })} />
                </G.FormInputArea>

                <G.FormInputArea style={{ width: '360px' }}>
                  <G.FormInputLabel>
                    Cidade
                  </G.FormInputLabel>
                  <G.FormInput defaultValue={!vrfData ? '' : myCompanyData[0].address_city} {...register("address_city", { required: true })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '96px' }}>
                  <G.FormInputLabel>
                    UF
                  </G.FormInputLabel>
                  <G.FormInput maxLength={2} defaultValue={!vrfData ? '' : myCompanyData[0].address_state_acronym} {...register("address_state_acronym", { required: true }, { setValueAs: v => v.toUpperCase() })} />
                </G.FormInputArea>
                <G.FormInputArea style={{ width: '140px' }}>
                  <G.FormInputLabel>
                    CEP
                  </G.FormInputLabel>
                  <G.FormInput 
                    type='text'
                    name='cepNumber'
                    placeholder=''
                    value={mask(cepNumber, ['99999-999'])}
                    onChange={onChangeCep}
                  />
                </G.FormInputArea>


                <G.BreakLine style={{ height: '12px' }}/>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  {(Object.keys(errors).length > 0 || stateErrors) &&  
                    <div role="alert" style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '72%', padding: '7px', margin: '16px 0px 0px 0px', borderRadius: '4px', fontSize: '12px', backgroundColor: '#FCAC9B', color: '#000' }}>
                      <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                      <div style={{ width: '84%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{'TODOS OS CAMPOS DEVEM ESTAR PREENCHIDOS!'}</div>
                      <div style={{ width: '08%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><WarningRoundedIcon style={{ color: '#333', marginRight: '5px' }} /></div>
                    </div>
                  }
                </div>

                <G.BreakLine style={{ height: '12px' }}/>

                <G.FieldArea style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: '10px', marginBottom: '10px' }}>
                  <G.FormInputLabel style={{ width: '920px', justifyContent: 'flex-start', border: 'none', backgroundColor: '#f0f0f0', borderRadius: '5px 5px 0px 0px', borderBottom: '1px solid #E0E0E0' }}>
                    Observações
                  </G.FormInputLabel>
                  <G.TextArea 
                    {...register("note", { setValueAs: v => v === '' ? null : v })} 
                    style={{ width: '900px', height: '96px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '0px 0px 5px 5px', fontFamily: 'inherit', color: '#222', fontSize: '14px' }}
                  >
                    {!vrfData ? '' : myCompanyData[0].note}
                  </G.TextArea>
                </G.FieldArea>

              </G.BoxBody>

            </G.Box>
          </>
        }
      </G.Body>


      <G.Footer style={{ color: '#333', fontSize: '14px' }}>
        {/* <p> Copyright <CopyrightIcon style={{ fontSize: '14px' }}/> {new Date().getFullYear()} – Joy Soluções e Tecnologia Ltda. </p> */}
      </G.Footer>

    </G.Container>

  );
}
