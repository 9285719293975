import React from 'react';
import * as G from '../../styles/global'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CloseIcon from '@mui/icons-material/Close';



export const EmptyAlert = ({textAlert}) => {


    return (
            <G.OptionsArea style={{justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '15px'}}>
            <ErrorOutlineRoundedIcon style={{fontSize: '60px', color: '#777' }}/>
            <G.InfoText style={{borderBottom: '1px solid #ccc', paddingBottom: '7px', marginTop: '0px', color: '#777', fontSize: '14px', textAlign: 'center' }}>
            {textAlert}
           </G.InfoText>
           </G.OptionsArea>
    );
}
