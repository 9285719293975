import styled from 'styled-components';


export const SearchArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border: 1px solid #E5E5E5;
  border-radius: 6px;
`;

export const SearchIconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 100%;
  padding: 0px;
  // border-right: 0.5px solid #E5E5E5;
  border-radius: 5px 0px 0px 5px;
  background-color: #F0F0F0;
  color: #444;
`;

export const SearchInputArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 220px;
  height: 100%;
  padding: 0px;
  // border-left: 0.5px solid #e5e5e5;
  border-radius: 0px 5px 5px 0px;
  outline: none;
  background-color: #FBFBFB;
  font-family: inherit;
  font-size: 12px;
`;


export const SearchInput = styled.input`
  width: 200px;
  height: 40px;
  padding: 0px 12px;
  // border: 1px solid #e5e5e5;
  // border-left: none;
  border-radius: 0px 6px 6px 0px;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  font-size: 12px;
`;
